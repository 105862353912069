<template>
  <div class="reset-header date-picker">
    <div class="header">日期</div>
    <el-date-picker
      v-if="resetDisabled"
      popper-class="date-picker-container"
      v-model="dateValue"
      clearable
      type="daterange"
      size="medium"
      @blur="addBorder(false)"
      @focus="addBorder(true)"
      @change="changeDateValue"
      :disabled-date="disabledDate">
    </el-date-picker>
    <div class="date-content" :class="{borderColor: borderColor}">
      <div v-show="dateTime">{{dateTime}}</div>
      <div v-show="!dateTime" class="placeholder">请选择</div>
      <span class="iconfont icon-lurukuang_rili"></span>
    </div>
  </div>
</template>

<script>
import { defineComponent, nextTick, ref, watch, computed } from 'vue'
import { dateFormatter } from '@/utils/index'
import key from './shopKey'
export default defineComponent({
  name: 'DatePicker',
  emits: {
    onDateValueEvent: null
  },
  props: {
    excludeTime: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, context) {
    const dataValue = JSON.parse(localStorage.getItem(`${key()}_date`))
    const dateText = localStorage.getItem(`${key()}_date_value`)
    const handlerDate = dataValue ? dataValue.map(i => Date.parse(i)) : []
    const dateValue = ref(handlerDate)
    const resetDisabled = ref(true)
    const excludeTimeLocal = JSON.parse(localStorage.getItem(`${key()}_date_picker`)) || {}
    let excludeTime = Object.keys(props.excludeTime).length ? props.excludeTime : excludeTimeLocal
    // 监听事件选择
    const dateTime = ref(dateText || '')

    context.emit('onDateValueEvent', dataValue || [])

    const changeDateValue = (val) => {
      if (!val) return

      const time = `${dateFormatter(val[0], 'YYYY/MM/DD')} - ${dateFormatter(val[1], 'YYYY/MM/DD')}`
      dateTime.value = time

      localStorage.setItem(`${key()}_date_value`, dateTime.value)
      const date = [dateFormatter(val[0], 'YYYY/MM/DD'), dateFormatter(val[1], 'YYYY/MM/DD')]
      localStorage.setItem(`${key()}_date`, JSON.stringify(date))

      context.emit('onDateValueEvent', date)
    }

    // 页面初始化验证

    watch(() => props.excludeTime, () => {
      excludeTime = props.excludeTime

      const { start, end } = excludeTime
      if (
        !start ||
        !end ||
        dateValue.value[0] < Date.parse(`${start} 00:00:00`) ||
        dateValue.value[1] > Date.parse(`${end} 23:59:59`)
      ) {
        dateValue.value = []
        dateTime.value = ''
        context.emit('onDateValueEvent', [])
        localStorage.setItem(`${key()}_date_value`, '')
        localStorage.setItem(`${key()}_date`, JSON.stringify([]))
      }
      resetDisabled.value = false
      nextTick(() => {
        resetDisabled.value = true
      })
      localStorage.setItem(`${key()}_date_picker`, JSON.stringify(excludeTime))
    }, { deep: true })
    // 屏蔽接口传来的开始时间与结束时间
    const disabledDate = computed(() => time => {
      const { start, end } = excludeTime
      const date = new Date()
      const getYear = date.getFullYear()
      const getMonth = date.getMonth() + 1
      const getDay = date.getUTCDate()
      if (!start || !end) {
        return true
      } else {
        const disabledTime =
          time.getTime() < Date.parse(`${start} 00:00:00`) ||
          time.getTime() > Date.parse(`${end} 23:59:59`) ||
          Date.parse(`${getYear}-${getMonth}-${getDay}`) - time.getTime() < 3 * 24 * 60 * 60 * 1000 ||
          Date.parse(`${getYear}-${getMonth}-${getDay}`) - time.getTime() > 730 * 24 * 60 * 60 * 1000
        return disabledTime
      }
    })

    const borderColor = ref(false)
    const addBorder = (value) => {
      borderColor.value = value
    }
    return {
      dateValue,
      changeDateValue,
      disabledDate,
      dateTime,
      addBorder,
      borderColor,
      resetDisabled
    }
  }
})
</script>

<style lang="scss">
.date-picker {
  display: flex;
  position: relative;
  flex: 1;
  justify-content: flex-end;
  .date-content {
    display: flex;
    width: 276px;
    height: 36px;
    background: #FBF8F3;
    display: flex;
    align-items: center;
    padding: 0 12px;
    color: #242426;
    font-size: 14px;
    box-sizing: border-box;
    border: 1px solid #FBF8F3;
    border-radius: 0 2px 2px 0px;
    div {
      flex: 1;
      text-align: left;
      margin-right: 12px;
    }
    .placeholder {
      color: #BFBBB4;
    }
    .iconfont {
      color: rgb(170, 149, 144);
      &:before {
        font-size: 10px;
      }
    }
  }
  .borderColor {
    border-color: #F04848;
  }
  .el-date-editor {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    &:hover {
      &+div {
        border-color: #F04848;
      }
    }
  }
}
.date-picker-container.el-popper {
  z-index: 1000 !important;
  .el-picker-panel__shortcut {
    float: left;
    width: 50%;

    &:nth-child(n+9) {
      width: 100%;
    }
  }
}
</style>
