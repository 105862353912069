<template>
  <div class="table-condition">
    <div>
      按
      <el-select v-model="tableObj.date_type" size="medium">
        <el-tooltip
          v-for="item in timeSelect"
          :key="item.value"
          :disabled="item.disabled"
          effect="dark"
          :content="item.toolTip"
          placement="top"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            :disabled="!item.disabled"
          >
          </el-option>
        </el-tooltip>
      </el-select>
    </div>
    <div class="table-top">
      {{ firstText }}<el-select v-model="tableObj.top" placeholder="请选择" size="medium">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'

const topOptions = [
  { label: 'TOP10', value: '10' },
  { label: 'TOP20', value: '20' },
  { label: 'TOP30', value: '30' },
  { label: 'TOP50', value: '50' },
  { label: 'TOP100', value: '100' }
]

export default defineComponent({
  props: {
    firstText: {
      type: String,
      default: '查看'
    },
    lastText: {
      type: String,
      default: '商品'
    },
    resetTimes: {
      type: Object,
      default: () => {}
    },
    topNum: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ''
    }
  },
  setup (props, context) {
    const store = useStore()
    const phone = store.state.phone
    const top = localStorage.getItem(`${phone}_table_top${props.name}`)
    const dateType = localStorage.getItem(`${phone}_table_date_type${props.name}`)
    const tableObj = reactive({
      top: top || '10',
      date_type: dateType || ''
    })
    context.emit('setData', tableObj)
    const topOption = ref(topOptions)

    const resetOption = () => {
      if (store.state.nowUrl === '/industry/rank') {
        const isNoneAll = topOption.value.every(t => t.value !== '-9')
        isNoneAll && topOption.value.push({ label: '全部', value: '-9' })
      } else {
        topOption.value = topOption.value.filter(t => t.value !== '-9')
      }
      const resetTop = topOption.value.some(t => t.value === tableObj.top)
      if (!resetTop) {
        tableObj.top = topOption.value[0] ? topOption.value[0].value : ''
      }
    }
    watch(() => props.topNum, () => {
      if (!props.topNum) {
        topOption.value = topOptions
        resetOption()
        return
      }
      topOption.value = JSON.parse(JSON.stringify(topOptions)).filter(op => {
        return Number(op.value) <= props.topNum
      })
      resetOption()
    }, { immediate: true })

    watch(() => store.state.nowUrl, () => {
      resetOption()
    })

    const timeSelect = reactive([])
    watch(props.resetTimes, () => {
      const timeOptions = [
        { label: '日', value: 'd', disabled: true, toolTip: '所选日期范围在14天以内才可以按日查看' },
        { label: '周', value: 'w', disabled: true, toolTip: '所选日期范围在364天以内才可以按周查看' },
        { label: '月', value: 'm', disabled: true }
      ]
      const dateObj = props?.resetTimes?.dateValue
      if (dateObj?.start_time && dateObj?.end_time) {
        const { start_time: start, end_time: end } = dateObj
        const day = (new Date(end) - new Date(start)) / (24 * 60 * 60 * 1000)
        timeOptions[1].disabled = day < 364
        timeOptions[0].disabled = day < 14
      }
      // 重置单选框value
      const selectValue = timeOptions.filter(t => t.value === tableObj.date_type && t.disabled)
      const selectOption = timeOptions.filter(t => t.disabled)
      if (!selectValue.length && selectOption.length) {
        tableObj.date_type = selectOption[0].value
      }
      timeSelect.length = 0
      timeSelect.push(...timeOptions)
      if (!timeSelect.length) {
        tableObj.date_type = ''
      }
    }, { immediate: true })

    watch(tableObj, () => {
      localStorage.setItem(`${phone}_table_top${props.name}`, tableObj.top)
      localStorage.setItem(`${phone}_table_date_type${props.name}`, tableObj.date_type)
      context.emit('setData', tableObj)
    })
    return { tableObj, options: topOption, timeSelect }
  }
})
</script>
<style scoped lang="scss">
.table-condition {
  display: flex;
  padding-left: 16px;
  &>div {
    font-size: 14px;
  }
  .el-select {
    width: 64px;
    margin-left: 6px;
    margin-right: 8px;
  }
  .table-top {
    .el-select {
      width: 104px;
    }
  }
}
</style>
