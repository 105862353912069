<template>
<el-popover
    ref="popover"
    placement="bottom-start"
    :width="200"
    trigger="click"
    @show="toggle"
    @hide="toggle"
    popper-class="select-dropdown-focus-popper"
  >
    <div class="first-content">
      <div class="second-content-box">
        <div
          v-for="({id, name}, i) of selectOption"
          :key="i"
          @click="selectFirst(id, name)"
          :class="{'select-cell': selectFirstId === id}"
        >
          {{name}}
          <span class="iconfont icon-jiantou_xiangyou"></span>
        </div>
      </div>
    </div>
    <div class="second-content first-content" v-loading="loading">
      <div class="second-content-box" v-if="secondOption.length">
        <div
          v-for="({id, name}, i) of secondOption"
          :key="i"
          :class="{'select-cell': selectSecondId === id}"
          @click="selectSecond(id)"
        >{{name}}</div>
      </div>
      <div
          v-else
          class="no-data"
        >
          <img src="@/assets/no-data/no-setting.png">
          <span>暂无关注{{pageName}}</span>
        </div>
    </div>
    <template #reference>
      <div class="select-dropdown-focus reset-header">
        <div class="header">{{start}}</div>
        <div class="category-input" :class="{'border-color': isToggle}">
          <div v-if="categoryValue">{{ categoryValue }}</div>
          <div v-else class="placeholder">请选择</div>
          <span class="iconfont icon-lurukuang_xiala" :class="{up: isToggle}"></span>
        </div>
      </div>
    </template>
  </el-popover>
</template>

<script>
import { defineComponent, ref } from 'vue'
import axios from '@/utils/request'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'SelectDropdown',
  props: {
    startTitle: {
      type: String,
      default: '头文案'
    },
    brandType: {
      type: String,
      default: ''
    }
  },
  emits: {
    onSelectDropdownFocus: null
  },
  setup (props, context) {
    const store = useStore()
    const phone = store.state.phone
    const value = ref(top || '')
    const start = ref(props.startTitle)
    const name = { shop: '店铺', brand: '品牌' }
    const pageName = name[props.brandType]

    const selectFirstIdLocal = localStorage.getItem(`${phone}_${props.brandType}_dropdown`)
    const idLocal = localStorage.getItem(`${phone}_${props.brandType}_setting`)
    const firstNameLocal = localStorage.getItem(`${phone}_${props.brandType}_firstName`) || ''
    const secondNameLocal = localStorage.getItem(`${phone}_${props.brandType}_secondName`) || ''

    const selectOption = ref([])
    axios.get('/api/v1/basedata/get_website').then(res => {
      if (res && res.error_code === 0) {
        selectOption.value = res.data
      }
    })

    const categoryValue = ref(
      firstNameLocal && secondNameLocal ? `${firstNameLocal}/${secondNameLocal}` : ''
    )
    const popover = ref(null)
    const selectSecondId = ref(Number(idLocal) || idLocal || '')
    const selectSecond = (id) => {
      if (selectSecondId.value === id) return

      selectSecondId.value = id
      const { name } = secondOption.value.find(obj => id === obj.id)
      categoryValue.value = `${firstName}/${name}`
      popover.value.hide()
      localStorage.setItem(`${phone}_${props.brandType}_dropdown`, selectFirstId.value)
      localStorage.setItem(`${phone}_${props.brandType}_setting`, id)
      localStorage.setItem(`${phone}_${props.brandType}_firstName`, firstName || '')
      localStorage.setItem(`${phone}_${props.brandType}_secondName`, name)
      context.emit(
        'onSelectDropdownFocus',
        {
          platform_id: selectFirstId.value,
          brand_ids: id,
          firstName,
          secondName: name
        }
      )
    }

    const secondOption = ref([])
    const loading = ref(false)
    let currentId = ''
    const getSecondList = (id) => {
      currentId = id
      const url = {
        shop: '/api/v1/customer/subscribe_shop_list',
        brand: '/api/v1/customer/subscribe_brand_list'
      }
      const params = { website_id: id }
      loading.value = true
      axios.get(url[props.brandType], { params }).then((res) => {
        if (res && res.error_code === 0) {
          secondOption.value = res.data[`${props.brandType}_list`].map(
            (d) => {
              return { id: d.id || d.name, name: d.name }
            }
          )
          selectSecond(selectSecondId.value)
        }
      }).finally(() => {
        loading.value = false
      })
    }

    const selectFirstId = ref(Number(selectFirstIdLocal) || '')

    let firstName = firstNameLocal || ''
    const selectFirst = (id, name) => {
      selectFirstId.value = id
      firstName = name
      getSecondList(id)
    }

    selectFirstId.value && selectFirst(selectFirstId.value, firstNameLocal)

    const isToggle = ref(false)
    const toggle = () => {
      isToggle.value = !isToggle.value
      if (isToggle.value) {
        getSecondList(currentId)
      }
    }
    return {
      value,
      start,
      toggle,
      isToggle,
      categoryValue,
      selectFirstId,
      selectSecondId,
      selectOption,
      selectFirst,
      selectSecond,
      secondOption,
      loading,
      popover,
      pageName
    }
  }
})
</script>

<style lang="scss">
  .select-dropdown-focus-popper.el-popper {
    width: auto !important;
    padding: 0 !important;
    display: flex;
    z-index: 1000 !important;
    .first-content {
      width: 143px;
      padding: 9px 0;
      border-right: 1px solid #E9E9F2;
      max-height: 343px;
      overflow-y: auto;
      .select-cell {
        background: rgb(255, 246, 246);
        color: #F04848;
        span {
          color: #F04848;
        }
      }
      .second-content-box {
        overflow-y: auto;
        &>div {
          height: 36px;
          line-height: 34px;
          padding: 0 8px 0 12px;
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: flex;
          justify-content: space-between;
          &:hover {
            background: rgb(255, 246, 246);
            color: #F04848;
            span {
              color: #F04848;
            }
          }
        }
      }
      .no-data {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction:column;
        height: 100%;
        img {
          width: 120px;
          height: 100px;
        }
        span {
          margin-top: 12px;
          text-align: center;
          color: #B4B7BF;
        }
      }
    }
    .second-content {
      width: 220px;
      height: 272px;
      overflow-y: auto;
      .el-input--medium {
        width: 196px;
        margin-bottom: 17px;
        margin-left: 13px;
        .el-input__suffix {
          right: 12px;
        }
      }
      .second-content-box {
        &>div {
          display: block !important;
        }
      }
    }
    &[data-popper-placement="bottom-start"] {
      margin-top: -11px !important;
      .el-popper__arrow {
        display: none;
      }
    }
  }
  .select-dropdown-focus {
    display: flex;
    .category-input {
      width: 144px;
      height: 36px;
      background: #FBF8F3;
      line-height: 36px;
      text-align: left;
      padding: 0 12px;
      font-size: 14px;
      color: #242426;
      display: flex;
      border: 1px solid #FBF8F3;
      box-sizing: border-box;
      &>div {
        flex: 1;
        padding-right: 12px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &:hover{
        border-color: #F04848;
      }
      .placeholder {
        color: #BFBBB4;
      }
      .iconfont {
        transition: transform .3s,-webkit-transform .3s;
        transform: rotateZ(0);
        color: rgb(170, 149, 144);
        display: flex;
        align-items: center;
        &:before {
          font-size: 12px;
        }
      }
      .up {
        transition: transform .3s,-webkit-transform .3s;
        transform: rotateZ(180deg);
      }
    }
    .border-color {
      border-color: #F04848;
    }
  }
</style>
