<template>
  <div class="data-summary">
    <div>
      <span class="summary-header">{{ header }}</span>
      <el-tooltip popper-class="search-tooltip" append-to-body effect="dark" placement="bottom">
        <template #content>
          <header>{{header}}:</header>
          <span v-for="text of toolTip.split('</br>')" :key="text">
            {{text}}<br/>
          </span>
        </template>
        <span class="iconfont icon-bangzhu"></span>
      </el-tooltip>
      <span class="number">{{ text || 0 }}</span>
      <span>{{ unit }}</span>
    </div>
    <div class="img-box">
      <img src="@/assets/industry/amount.png" v-if="type === 'amount'" class="amount">
      <img src="@/assets/industry/number.png" v-if="type === 'number'" class="number">
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    header: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    toolTip: {
      type: String,
      default: ''
    },
    unit: {
      type: String,
      default: '元'
    },
    type: {
      type: String,
      default: 'amount'
    }
  }
})
</script>
<style scoped lang="scss">
.data-summary {
  height: 68px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  background: #F7F8FA;
  border-radius: 2px;
  &>div {
    display: flex;
    align-items: center;
  }
  .summary-header {
    font-size: 14px;
  }
  .iconfont {
    margin-left: 5px;
    color: #B4B4BF;
  }
  .number {
    font-size: 20px;
    margin-left: 16px;
    margin-right: 4px;
    font-weight: 500;
  }
  .img-box {
    flex: 1;
    justify-content: flex-end;
    .amount {
      width: 64px;
      height: 64px;
    }
    .number {
      width: 64px;
      height: 64px;
      margin-right: 0;
      margin-left: 0;
    }
  }
}
</style>
