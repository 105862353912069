/**
 * type = 1  登录
 * type =2 行业排行-折线图
 * type =2 行业排行-表格
 * type = 3 品牌排行-折线图
 * type = 3 品牌排行-表格
 * type =4 品牌详情-折线图
 * type =4 品牌详情-表格
 * type =5 店铺排行-折线图
 * type =5 店铺排行-表格
 * type =6  店铺详情-折线图
 * type =6  店铺详情-表格
 * type = 7 商品排行-折线图
 * type = 7 商品排行-表格
 * type =8 商品详情-折线图
 * type = 8 商品详情-表格
 * type = 16 后台登录
 *
 * download = 1 图
 * download = 2 表格
 */
import axios from '@/utils/request'

const requestMap = {
  '/api/v1/industry_data/statistics': ['chart', 2],
  '/api/v1/industry_data/data_list': ['table', 2],
  '/api/v1/brand_data/statistics': ['chart', 3],
  '/api/v1/brand_data/data_list': ['table', 3],
  '/api/v1/brand_data/detail_statistics': ['chart', 4],
  '/api/v1/brand_data/detail_data_list': ['table', 4],
  '/api/v1/bi_shop_data/statistics': ['chart', 5],
  '/api/v1/bi_shop_data/data_list': ['table', 5],
  // type=category，goods
  '/api/v1/bi_shop_data/detail_statistics': ['chart', 6],
  '/api/v1/bi_shop_data/detail_data_list': ['table', 6],
  '/api/v1/product_data/statistics': ['chart', 7],
  '/api/v1/product_data/data_list': ['table', 7],
  '/api/v1/product_data/goods_detail': ['chart', 8],
  '/api/v1/product_data/goods_table': ['table', 8]
}
const currentParams = {}
export function setParams (url, params, data) {
  if (requestMap[url]) {
    // 存储当前请求条件，下载或者切换图表直接拿来用
    const key = `${requestMap[url][0]}${params.type === 'goods' ? 'goods' : ''}`
    addGoodsName(url, data, params)
    currentParams[key] = { url, params }

    // 添加图类型字段
    initCartData(url)
  }
}
export function saveLog (url, params) {
  axios.post('/api/v1/operate_log/save_log', {
    ...params,
    type: requestMap[url][1]
  })
}

function initCartData (url) {
  const isOperation = requestMap[url][0] === 'chart' && !currentParams.chart.params.operation && requestMap[url][1] !== 14
  isOperation && (currentParams.chart.params.operation = 'bar')
}

let proTitle = ''
function addGoodsName (url, data, params) {
  if (requestMap[url][1] === 8 && requestMap[url][0] === 'chart') {
    proTitle = data.goodsDetail.pro_title
    params.pro_title = proTitle
  } else if (requestMap[url][1] === 8 && requestMap[url][0] === 'table') {
    params.pro_title = proTitle
  }
}

export function loginLog (type) {
  axios.post('/api/v1/operate_log/save_log', {
    type,
    search: ''
  })
}

export function downloadEchartLog () {
  const { url, params } = currentParams.chart
  saveLog(url, { ...params, download: 1 })
}

export function downloadTableLog (index) {
  const { url, params } = currentParams[`table${index === '1' ? 'goods' : ''}`]
  saveLog(url, { ...params, download: 2 })
}
