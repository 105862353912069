<template>
  <div class="echart-condition">
    <el-checkbox v-model="chartObj.amount" @change="setCheck($event, 'number')"
      >销售金额</el-checkbox
    >
    <el-checkbox v-model="chartObj.number" @change="setCheck($event, 'amount')"
      >销售件数</el-checkbox
    >
    <div class="read-top">
      <div class="read-select">
        按
        <el-select v-model="chartObj.date_type" @change="setTop" size="medium">
          <el-tooltip
            v-for="item in timeSelect"
            :key="item.value"
            :disabled="item.disabled"
            effect="dark"
            :content="item.toolTip"
            placement="top"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              :disabled="!item.disabled"
            >
            </el-option>
          </el-tooltip>
        </el-select>
      </div>
      查看
      <el-select
        v-model="chartObj.top"
        @change="setTop"
        size="medium"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </div>
</template>
<script>
import { defineComponent, reactive, ref, watch } from 'vue'
// import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'

const topOptions = [
  { label: 'TOP10', value: '10' },
  { label: 'TOP20', value: '20' },
  { label: 'TOP30', value: '30' },
  { label: 'TOP50', value: '50' },
  { label: 'TOP100', value: '100' }
]

export default defineComponent({
  props: {
    resetTimes: {
      type: Object,
      default: () => {}
    },
    topNum: {
      type: Number,
      default: 0
    }
  },
  setup (props, context) {
    const checked = ref(false)
    const store = useStore()
    const phone = store.state.phone
    const topOption = ref(topOptions)
    const chartCondition = JSON.parse(localStorage.getItem(`${phone}_echart_condition`))
    const chartObj = reactive({
      amount: true,
      number: true,
      top: chartCondition ? chartCondition.top : '10',
      date_type: chartCondition ? chartCondition.date_type : ''
    })
    context.emit('setTop', chartObj)

    const setTop = () => {
      context.emit('setTop', { top: chartObj.top, date_type: chartObj.date_type })
    }
    const setCheck = (event, type) => {
      // if (!event && !chartObj[type]) {
      //   ElMessage({ message: '至少选择1个选项', type: 'error' })
      //   const key = type === 'amount' ? 'number' : 'amount'
      //   chartObj[key] = true
      //   return
      // }
      context.emit('setCheck', chartObj)
    }

    watch(chartObj, () => {
      localStorage.setItem(
        `${phone}_echart_condition`,
        JSON.stringify({ top: chartObj.top, date_type: chartObj.date_type })
      )
    })

    const resetOption = () => {
      if (store.state.nowUrl === '/industry/rank') {
        const isNoneAll = topOption.value.every(t => t.value !== '-9')
        isNoneAll && topOption.value.push({ label: '全部', value: '-9' })
      } else {
        topOption.value = topOption.value.filter(t => t.value !== '-9')
      }
      const resetTop = topOption.value.filter(t => t.value === chartObj.top)
      if (!resetTop.length) {
        chartObj.top = topOption.value[0] ? topOption.value[0].value : ''
      }
    }
    watch(() => props.topNum, () => {
      if (!props.topNum) {
        topOption.value = topOptions
        resetOption()
        return
      }
      topOption.value = JSON.parse(JSON.stringify(topOptions)).filter(op => {
        return Number(op.value) <= props.topNum
      })
      resetOption()
    }, { immediate: true })
    watch(() => store.state.nowUrl, () => {
      resetOption()
    })

    const timeSelect = reactive([])
    watch(props.resetTimes, () => {
      const timeOptions = [
        { label: '日', value: 'd', disabled: true, toolTip: '所选日期范围在14天以内才可以按日查看' },
        { label: '周', value: 'w', disabled: true, toolTip: '所选日期范围在364天以内才可以按周查看' },
        { label: '月', value: 'm', disabled: true }
      ]
      const dateObj = props?.resetTimes?.dateValue
      if (dateObj?.start_time && dateObj?.end_time) {
        const { start_time: start, end_time: end } = dateObj
        const day = (new Date(end) - new Date(start)) / (24 * 60 * 60 * 1000)
        timeOptions[1].disabled = day < 364
        timeOptions[0].disabled = day < 14
      }
      // 重置单选框value
      const selectValue = timeOptions.filter(t => (t.value === chartObj.date_type) && t.disabled)
      const selectOption = timeOptions.filter(t => t.disabled)
      if (!selectValue.length && selectOption.length) {
        chartObj.date_type = selectOption[0].value
        context.emit('setTop', chartObj)
      }
      if (!timeOptions.length) {
        chartObj.date_type = ''
      }
      timeSelect.length = 0
      timeSelect.push(...timeOptions)
    }, { immediate: true })

    return { checked, chartObj, options: topOption, setCheck, setTop, timeSelect }
  }
})
</script>
<style lang="scss">
.echart-condition {
  .el-checkbox__inner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-select {
    width: 64px;
    margin-left: 6px;
    margin-right: 8px;
  }
  .read-top {
    height: 16px;
    margin-left: 24px;
    padding-left: 24px;
    border-left: 1px solid #e9e9f2;
    font-size: 14px;
    .el-select {
      width: 102px;
      margin-left: 8px;
    }
    .read-select {
      .el-select {
        width: 64px;
      }
    }
  }
}
</style>
