import { reactive } from 'vue'

function chartsConditionFn (chartCondition, constData, chartData) {
  Object.keys(constData).forEach(d => {
    chartData[d] = JSON.parse(JSON.stringify(constData[d]))
  })
  if (chartCondition.amount === false) {
    chartData.sales_amount = chartData.sales_amount || {}
    chartData.sales_amount.data = []
    chartData.sales_amount_bar = []
  }
  if (chartCondition.number === false) {
    chartData.sales_num = chartData.sales_num || {}
    chartData.sales_num.data = []
    chartData.sales_num_bar = []
  }
}

const echartData = reactive({})
function resetData (data) {
  const deepCloneData = JSON.parse(JSON.stringify(data))
  if (deepCloneData?.sales_amount?.data) {
    deepCloneData.sales_amount.data.forEach((l, i) => {
      l.color = randomColor(i, 'line')
      l.type = '销售金额'
    })
  }
  if (deepCloneData?.sales_num?.data) {
    deepCloneData.sales_num.data.forEach((l, i) => {
      l.color = randomColor(i, 'line')
      l.type = '销售件数'
    })
  }
  if (deepCloneData?.sales_amount_bar) {
    deepCloneData.sales_amount_bar = (deepCloneData.amount_sorts || []).map(l => {
      return deepCloneData.sales_amount_bar[l]
    })
    const isEmpty = deepCloneData.sales_amount_bar.every(d => !d)
    if (isEmpty) {
      deepCloneData.sales_amount_bar = []
    } else {
      deepCloneData.sales_amount_bar.forEach((l, i) => {
        l.color = randomColor(i, 'pie')
        l.type = '销售金额'
      })
    }
  }
  if (deepCloneData?.sales_num_bar) {
    const key = deepCloneData?.sales_amount_bar?.length ? 'amount_sorts' : 'num_sorts'
    deepCloneData.sales_num_bar = (deepCloneData[key] || []).map(l => {
      return deepCloneData.sales_num_bar[l]
    })
    const isEmpty = deepCloneData.sales_num_bar.every(d => !d)
    if (isEmpty) {
      deepCloneData.sales_num_bar = []
    } else {
      deepCloneData.sales_num_bar.forEach((l, i) => {
        l.color = randomColor(i, 'pie')
        l.type = '销售件数'
      })
    }
  }

  Object.keys(deepCloneData).forEach(d => {
    echartData[d] = deepCloneData[d]
  })
}

const randomColor = (i, type) => {
  const lineColor = ['#64B5F6', '#81C784', '#ffa726', '#ff8A65', '#A2AEDF', '#4DB6AC', '#F6BE1A', '#E18989', '#9CCC65', '#4FC3F7', '#BE98C5', '#FDD835', '#E196B1', '#4DD0E1', '#CDDC39', '#91C0E7']
  const pieColor = ['#7BC0F7', '#69CCF8', '#67D7E5', '#67C1B8', '#94CF96', '#ABD37C', '#D4E156', '#FDDE53', '#F7C83C', '#FFB446', '#FF9B7C', '#E59A9A', '#E5A6BC', '#C8A7CD', '#B0BAE4', '#A1C9EA']
  const colorArr = type === 'line' ? lineColor : pieColor
  return i < colorArr.length ? colorArr[i] : colorArr[i % colorArr.length]
}

const filterData = () => {}
export { chartsConditionFn, resetData, filterData, echartData }
