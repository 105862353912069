import { createStore } from 'vuex'
import req from '@/utils/request'
import { geetestAction, getQueryVariable } from '@/utils/index'

export default createStore({
  state: {
    isShowMenu: false, // 显示：true 隐藏：false
    menuType: 1, // 两种类型的菜单 数据：1 用户：2
    isLogin: false, // 登录：true 未登录：false
    token: '',
    parentId: '',
    phone: '',
    nowUrl: '/login',
    username: '',
    validityTime: '',
    securityKey: '',
    generateKey: '',
    permission: [],
    valiCodeLock: false,
    closeArr: [],
    insider: false
  },
  mutations: {
    setcloseArr (state, arr) {
      state.closeArr = arr
    },
    clearcloseArr (state) {
      state.closeArr.length = 0
    },
    changeNowUrl (state, status) {
      state.nowUrl = status
    },
    changeMenuState (state, status) {
      state.isShowMenu = status
    },
    changeLoginState (state, status) {
      // 登录成功后 菜单和菜单类型 与登录状态一起变更
      state.isLogin = status
      state.isShowMenu = status
      state.menuType = 1
    },
    setInsider (state, status) {
      state.insider = status
    },
    changeMenuType (state, type) {
      state.menuType = type
    },
    setToken: (state, token) => {
      state.token = token
    },
    setPhone: (state, data) => {
      state.phone = data
    },
    setUsername: (state, data) => {
      state.username = data
    },
    setParentId: (state, data) => {
      state.parentId = data
    },
    setValidityTime: (state, data) => {
      state.validityTime = data
    },
    setSecurityKey: (state, data) => {
      state.securityKey = data
    },
    setGenerateKey: (state, data) => {
      state.generateKey = data
    },
    setPermission: (state, data) => {
      state.permission = data
    },
    setValiCodeLock: (state, data) => {
      state.valiCodeLock = data
    }
  },
  actions: {
    // 变更url
    changeUrl ({ commit }, status) {
      commit('changeNowUrl', status)
    },
    // 变更菜单状态
    changeMemuStatus ({ commit }, status) {
      commit('changeMenuState', status)
    },
    // 获取ssotoken
    getSSOToken ({ commit }) {
      return new Promise(resolve => {
        req.get('/api/v1/authorization/get_token_by_ins', { params: { access_token: getQueryVariable('access_token') } }).then(res => {
          if (!res.error_code) {
            commit('setToken', res.data.token)
            window.localStorage.setItem('DATAZHI-TOKEN', res.data.token)
            resolve(res)
          }
        })
      })
    },
    // 登录
    login ({ commit, state }, info) {
      const login = (obj, resolve) => {
        req.post('/api/v1/login', JSON.stringify(obj), {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (!res.error_code) {
            commit('setToken', res.data.token)
            window.localStorage.setItem('DATAZHI-TOKEN', res.data.token)
            resolve(res)
          }
        }).finally(() => {
          commit('setValiCodeLock', false)
        })
      }
      return new Promise(resolve => {
        // 点击锁
        if (state.valiCodeLock) return
        commit('setValiCodeLock', true)

        geetestAction().then(result => {
          const obj = Object.assign(info.user, result)
          login(obj, resolve)
        }).finally(() => {
          commit('setValiCodeLock', false)
        })
      })
    },
    // 获取用户信息
    getInfo ({ commit }) {
      return new Promise(resolve => {
        req.get('/api/v1/customer/get_customer_info').then(res => {
          if (!res.error_code) {
            // eslint-disable-next-line camelcase
            const { phone, name, parent_id, validity_time, menu, insider } = res.data
            commit('setPhone', phone)
            commit('setUsername', name)
            commit('setParentId', parent_id)
            commit('setValidityTime', validity_time)
            commit('setPermission', menu)
            commit('changeLoginState', true)
            commit('setInsider', !insider)

            window.localStorage.setItem('DATAZHI-USER', JSON.stringify(res.data))
            resolve(res)
          }
        })
      })
    },
    // 退出
    quit ({ commit }) {
      return new Promise(resolve => {
        req.get('/api/v1/logout').then(res => {
          if (!res.error_code) {
            commit('changeLoginState', false)
            commit('setToken', '')
            commit('setPhone', '')
            commit('setUsername', '')
            commit('setParentId', '')
            commit('setValidityTime', '')
            commit('setPermission', [])
            window.localStorage.setItem('DATAZHI-TOKEN', '')
            window.localStorage.setItem('DATAZHI-USER', '')
            resolve(res.data.message)
          }
        }).catch(err => {
          console.log('quit err:', err)
        })
      })
    },
    // 获取验证码
    getValidateCode ({ commit }, info) {
      return new Promise(resolve => {
        geetestAction().then(result => {
          const data = Object.assign(info, result)
          req.post('/api/v1/verification/send_sms/', JSON.stringify(data), {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(res => {
            if (!res.error_code) {
              commit('setSecurityKey', res.data.security_key)
              resolve(res)
            }
          }).catch(err => {
            console.log('getValidateCode err:', err)
          })
        }).catch(err => {
          console.log('geetestAction err:', err)
        })
      })
    },
    // 验证 code 是否正确 提交找回密码
    validateCode ({ commit, state }, info) {
      return new Promise(resolve => {
        const data = Object.assign(info, {
          security_key: state.securityKey
        })
        req.post('/api/v1/verification/verify_sms', JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (!res.error_code) {
            commit('setGenerateKey', res.data.generate_key)
            resolve()
          }
        }).catch(err => {
          console.log('validateCode err:', err)
        })
      })
    },
    // 重置密码
    resetPassword ({ commit, state }, info) {
      return new Promise(resolve => {
        const data = Object.assign(info, {
          generate_key: state.generateKey
        })
        req.post('/api/v1/customer/change_password', JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          if (!res.error_code) {
            resolve(res.data.message)
          }
        }).catch(err => {
          console.log('validateCode err:', err)
        })
      })
    },
    // 修改手机号
    changePhone ({ commit }, phone) {
      commit('setPhone', phone)
    }
  }
})
