<template>
  <div class="shop-detail-tag">
    {{ shopDetail.secondName }}
    <div class="tag">{{ shopDetail.firstName }}</div>
  </div>
</template>
<script>

export default {
  props: {
    shopDetail: Object
  }
}
</script>
<style lang="scss">
  .shop-detail-tag {
    height: 18px;
    display: flex;
    align-items: center;
    border-left: 2px solid #F04848;
    font-size: 16px;
    padding-left: 22px;
    line-height: 18px;
    margin-left: -24px;
    font-weight: 500;
    // padding: 8px 24px;
    margin: 8px 0;
    margin-left: -24px;
    .tag {
      height: 22px;
      border-radius: 2px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      background: #F04848;
      margin-left: 8px;
      color: #fff;
      font-size: 12px;
    }
  }
</style>
