<template>
  <div class="no-data">
    <img v-if="isNetwork" src="@/assets/no-data/no-data.png" alt="">
    <img v-else src="@/assets/no-data/no-network.png" alt="">
    <span>{{isNetwork ? tipText : "无法连接到网络"}}</span>
  </div>
</template>
<script>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'

export default {
  props: {
    text: {
      type: String,
      default: '无相关数据'
    }
  },
  setup (props) {
    const route = useRoute()
    const tipText = computed(() => {
      return route.path === '/brand/detail' ? '暂无数据，请选择已关注品牌' : props.text
    })
    const isNetwork = ref(navigator.onLine)
    return { isNetwork, tipText }
  }
}
</script>

<style lang="scss" scoped>
  .no-data {
    height: 272px;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;

    span {
      font-size: 14px;
      color: #B4B7BF;
      line-height: 20px;
      margin-top: 12px;
    }

    img {
      width: 120px;
      height: 100px;
    }
  }
</style>
