import { nextTick } from 'vue'
const SVGA = require('svgaplayerweb')
let isCreateLoading = true
const create = () => {
  if (!isCreateLoading) {
    return
  }
  isCreateLoading = false
  nextTick(() => {
    const div = document.createElement('div')
    document.body.style.overflow = 'hidden'
    div.id = 'loading-box'
    div.style.position = 'fixed'
    div.style.top = '0'
    div.style.bottom = '0'
    div.style.left = '0'
    div.style.right = '0'
    div.style.background = 'rgb(255,255,255,.3)'
    div.style.zIndex = '2002'
    div.style.display = 'flex'
    div.style.alignItems = 'center'
    div.style.justifyContent = 'center'
    div.style.flexDirection = 'column'
    div.style.transition = 'opacity .3s'
    div.style.zIndex = '9999'

    const box = document.createElement('div')
    box.id = 'loading-child-box'
    box.style.width = '60px'
    box.style.height = '60px'

    const span = document.createElement('div')
    span.innerText = 'Loading...'
    span.style.lineHeight = '20px'
    span.style.marginTop = '4px'
    span.style.color = '#F04848'
    div.appendChild(box)
    div.appendChild(span)
    const bo = document.querySelector('#app')
    bo.insertBefore(div, bo.lastChild)
    trapezoidBox()
  })
}

const trapezoidBox = () => {
  var player = new SVGA.Player('#loading-child-box')
  var parser = new SVGA.Parser('#loading-child-box') // 如果你需要支持 IE6+，那么必须把同样的选择器传给 Parser。
  parser.load('https://res.smzdm.com/datazhi/loading.svga', function (videoItem) {
    player.setVideoItem(videoItem)
    player.startAnimation()
  })
}

const close = () => {
  const loadingDiv = document.querySelector('#loading-box')
  document.body.removeAttribute('style')
  if (loadingDiv) {
    document.querySelector('#app').removeChild(loadingDiv)
    isCreateLoading = true
  }
}
const loading = {
  create,
  close
}

export default loading
