<template>
  <div class="chart-time">
    <span class="bar-time">{{ barTime }}</span>
    <el-tooltip popper-class="search-tooltip" append-to-body effect="dark" placement="top">
      <template #content>
        <span>
          图中数据时间区间，是您选择日期范围所在的自然日、自然周、自然月、自然年。<br>
          （举例：您所选的日期6月1-7日。假如：1日所在第一周的周二，7日所在第二周的周一。如果选择以日查看则是7个自然日。但以周查看的话，则是覆盖两个自然周的数据，第一周：5月31日至6月6日，第二周：6月7日至6月13日。自然月、自然年以此类推。）
        </span>
      </template>
      <span class="iconfont icon-bangzhu"></span>
    </el-tooltip>
  </div>
</template>
<script>

export default {
  props: {
    barTime: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
  .chart-time {
    display: flex;
    align-items: center;
    .bar-time {
      margin-left: 16px;
      color: rgba(144, 147, 153, 1);
      font-size: 12px;
    }
    .icon-bangzhu {
      font-size: 14px;
      margin-left: 8px;
      color: rgba(180, 183, 191, 1);
    }
  }
</style>
