<template>
<div class="search-components-list" v-loading="loading">
  <div class="no-data search-tip" v-if="!searchList.length">
    <img src="@/assets/no-data/no-data.png" alt="">
    <span>无相关数据</span>
  </div>
  <div
    class="search-components"
    :class="{'select-list': currentList === list, 'exclude-click': list.is_permission === 0}"
    v-for="list of searchList"
    :key="list"
    v-html="getName(list)"
    @click="setCurrentList(list)">
  </div>
</div>
</template>
<script>
import { defineComponent, ref, reactive, watch } from 'vue'
import { debounce } from 'lodash'
import axios from '@/utils/request'

export default defineComponent({
  emits: {
    onCategoryData: null
  },
  props: {
    searchValue: String,
    websiteId: [String, Number]
  },
  setup (props, { emit }) {
    const searchList = reactive([])

    const loading = ref(false)
    const getCategoryList = debounce(() => {
      searchList.length = 0
      if (!props.searchValue) return
      loading.value = true
      axios.get(
        '/api/v1/basedata/get_categories_by_keyword',
        { params: { keyword: props.searchValue, website_id: props.websiteId } }).then(res => {
        searchList.push(...(res.data || []))
      }).finally(() => {
        loading.value = false
      })
    }, 300)

    watch(props, getCategoryList, { immediate: true })

    const getName = (list) => {
      let strArr = []
      list.origin_cate1_id !== -9 && (strArr.push(list.origin_cate1_name))
      list.origin_cate2_id !== -9 && (strArr.push(list.origin_cate2_name))
      list.origin_cate3_id !== -9 && (strArr.push(list.origin_cate3_name))
      list.origin_cate4_id && list.origin_cate4_id !== -9 && (strArr.push(list.origin_cate4_name))
      strArr = strArr.map(str => {
        return str.replace(
          new RegExp(props.searchValue, 'g'),
        `<span class="text-selectColor">${props.searchValue}</span>`
        )
      })
      return strArr.join(' > ')
    }

    const currentList = ref({})
    const setCurrentList = (list) => {
      currentList.value = list
      emitData(list)
    }

    const getEmitName = (list) => {
      return `${list.origin_cate1_id !== -9 ? list.origin_cate1_name : ''
      }${list.origin_cate2_id !== -9 ? '-' + list.origin_cate2_name : ''
      }${list.origin_cate3_id !== -9 ? '-' + list.origin_cate3_name : ''
      }${list.origin_cate4_id && list.origin_cate4_id !== -9 ? '-' + list.origin_cate4_name : ''}`
    }
    const emitData = (list) => {
      const selectList = {
        first: list.origin_cate1_id === -9 ? [] : [list.origin_cate1_id],
        second: list.origin_cate2_id === -9 ? [] : [list.origin_cate2_id],
        third: list.origin_cate3_id === -9 ? [] : [list.origin_cate3_id],
        fourth: list.origin_cate4_id === -9 ? [] : [list.origin_cate4_id || -9]
      }
      emit('onCategoryData', {
        list: selectList,
        time: { start: list.start_date, end: list.end_date },
        name: getEmitName(list)
      })
    }

    return {
      loading,
      searchList,
      currentList,
      getName,
      setCurrentList
    }
  }
})
</script>
