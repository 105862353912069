
<template>
  <el-tooltip
    effect="dark"
    content="全屏展示"
    placement="top"
  >
    <el-button
      icon="iconfont icon-quanping"
      size="small"
      class="screen-btn"
      type="primary"
      @click="dialogVisible = true"
    ></el-button>
  </el-tooltip>
  <el-dialog
    :title="dialogTitle"
    v-model="dialogVisible"
    @close="clearData"
    custom-class="table-dialog"
    top="24px"
    width="100%"
    append-to-body
  >
    <chartTable
      :tableData="dataObj"
      tooltipPlacement="bottom"
      :tableMaxHeight="tableMaxHeight"
      @goShop="goShop"
      :downloadName="downloadName"
      :firstColumns="firstColumns"
      :resetTimes="resetTimes"
    >
    </chartTable>
  </el-dialog>
</template>

<script>
import { computed, nextTick, reactive, ref, watch } from 'vue'
import chartTable from '@/components/chart-table/index'
import { useRoute } from 'vue-router'

const FIRST_COLUMNS = [
  {
    prop: 'amount',
    label: '销售金额'
  },
  {
    label: '销售件数',
    prop: 'num'
  }
]

export default {
  props: {
    tableData: {
      type: Object,
      default: () => {}
    },
    index: {
      type: String,
      default: ''
    },
    downloadName: {
      type: String,
      default: '类目表'
    },
    firstColumns: {
      type: Array,
      default: () => FIRST_COLUMNS
    },
    resetTimes: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    }
  },
  emits: {
    goShop: null
  },
  components: { chartTable },
  setup (props, { emit }) {
    const dialogVisible = ref(false)
    const dataObj = reactive({})
    watch(dialogVisible, () => {
      if (dialogVisible.value) {
        history.pushState(null, null, document.URL)
        nextTick(() => {
          dataObj.cols = props.tableData.cols
          dataObj.rows = props.tableData.rows
          dataObj.download_pms = props.tableData.download_pms
          computedTableMaxHeight()
        })
      }
    })

    const tableMaxHeight = ref('0')
    const computedTableMaxHeight = () => {
      tableMaxHeight.value = `${document.documentElement.clientHeight - 172}`
    }

    const clearData = () => {
      window.history.back()
      dialogVisible.value = false
    }

    const goShop = (row) => emit('goShop', row)

    const route = useRoute()
    const dialogTitle = computed(() => {
      return props.title || route.meta.title || ''
    })
    window.onresize = () => {
      computedTableMaxHeight()
    }
    window.addEventListener('popstate', () => {
      if (dialogVisible.value) {
        history.pushState(null, null, document.URL)
      }
    })
    return { dialogVisible, dataObj, goShop, tableMaxHeight, clearData, dialogTitle }
  }
}
</script>
<style lang="scss">
  @import './open-table.scss'
</style>
