import html2canvas from 'html2canvas'
import { zdmCaptcha } from 'zdm-utils'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
import store from '@/store/index'

/**
 * 根据权限查找优先级
 * @method findPriority
 *
 * @param  {Array}  p 菜单权限
 */
export const findPriority = p => {
  const menu = p
  if (menu.includes('industry.ranking')) {
    return '/industry/rank'
  } else if (menu.includes('brand.ranking')) {
    return '/brand/rank'
  } else if (menu.includes('brand.details')) {
    return '/brand/detail'
  } else if (menu.includes('shop.ranking')) {
    return '/shop/rank'
  } else if (menu.includes('shop.details')) {
    return '/shop/detail'
  } else if (menu.includes('product.ranking')) {
    return '/goods/rank'
  } else if (menu.includes('product.details')) {
    return '/goods/detail'
  }
}

/**
 * 验证密码
 * @method passwordValidate
 * @example **1122aa4455667
 *
 * @param  {String}  p 密码 要求数字/符号/字母任意两项 6-15位
 */
export const passwordValidate = p => {
  return /(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{6,15}$/.test(p)
}

/**
 * 表格转图表
 * @method tableToExcel
 *
 * @param  {String}  idName DOM选择器的名称
 * @param  {String}  filename 下载文件的名称
 */
export const tableToExcel = (idName, filename = 'data') => {
  const idBox = document.querySelector(`#${idName}`)
  const fix = idBox.querySelector('.el-table__fixed')
  let tableBook
  if (fix) { // 判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
    tableBook = XLSX.utils.table_to_book(idBox.removeChild(fix), { raw: true })
    idBox.appendChild(fix)
  } else {
    tableBook = XLSX.utils.table_to_book(idBox, { raw: true })
  }
  const tableWrite = XLSX.write(tableBook, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'array'
  })

  try {
    FileSaver.saveAs(
      new Blob([tableWrite], { type: 'application/octet-stream' }),
      `${filename}.xlsx`
    )
  } catch (err) {
    console.log('tableToExcel err:', err)
  }

  return tableWrite
}

/**
 * 格式化日期
 * @method dateFormatter
 *
 * @example
 *  dateFormatter(new Date(),'YYYY-MM-DD HH:mm:ss')
 *  // 2019-09-12 19:06:24
 *  dateFormatter(new Date(), 'YYYYMMDDHHmmss')
 *  // 20190912191133
 * // 13位时间戳
 * @param  {Number}  1587218357414
 * @param  {String}  'YYYY-MM-DD HH:mm:ss' / 'YYYYMMDDHHmmss'
 * @return {String}  2019-09-12 19:06:24 / 20190912191133
 */
export const dateFormatter = (_d = null, _fmt = 'YYYY-MM-DD') => {
  const date = _d ? new Date(_d) : new Date()
  const year = date.getFullYear() + ''
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minutes = date.getMinutes()
  const second = date.getSeconds()

  return _fmt.replace(/YYYY|yyyy/g, year)
    .replace(/YY|yy/g, year.substr(2, 2))
    .replace(/MM/g, (month < 10 ? '0' : '') + month)
    .replace(/DD/g, (day < 10 ? '0' : '') + day)
    .replace(/HH|hh/g, (hour < 10 ? '0' : '') + hour)
    .replace(/mm/g, (minutes < 10 ? '0' : '') + minutes)
    .replace(/ss/g, (second < 10 ? '0' : '') + second)
}

/**
* 过滤掉无权限的左侧菜单
* @method filterRouters
*
* @param  {Array}     arr    权限表
* @param  {Array}     routes 路由表
* @return {undefined} 返回 无
*/
export const filterRouters = (arr, routes) => {
  for (let j = 0; j < routes.length; j++) {
    const rt = routes[j]
    rt.hidden = !arr.includes(rt.meta.key)
    if (rt.children && rt.children.length) {
      filterRouters(arr, rt.children)
    }
  }
}

/**
* 极验
* @method geetestAction
*
* @return {undefined} 返回 无
*/
export const geetestAction = () => {
  return new Promise((resolve, reject) => {
    // 极验初始化
    zdmCaptcha({
      url: '/api/v1/verification/get_geetest?rand=1624428448921&scene=mobile_code',
      scene: 'mobile_code', // 场景 active：活动交互, login：登录注册, mobile_code: 验证码, ta_index：他人主页
      readyCallBack: () => {
        // 此处写初始化成功后，准备好的逻辑
        // 调起极验弹窗
        if (window.geetestObj) {
          window.geetestObj.verify()
        }
      },
      successCallBack: result => {
        // 此处写验证成功后执行的逻辑
        // 重置极验弹窗
        window.geetestObj.reset()
        resolve(result)
      },
      closeCallBack: () => {
        // 此处写关闭验证弹窗执行的逻辑
        // eslint-disable-next-line prefer-promise-reject-errors
        reject()
      }
    })
  })
}

/**
* 捕捉元素截图
* @method screenShot
*
* @param  {HTMLElement}   selector DOM选择器
* @return {undefined}     返回 无
*/
export const screenShot = (selector, resetDOM = false, name) => {
  window.pageYOffset = 0
  document.documentElement.scrollTop = 0
  document.body.scrollTop = 0
  const DOM = document.querySelector(selector)
  if (resetDOM) {
    const chartDOM = document.querySelector('#chart')
    chartDOM && (chartDOM.style.overflow = 'inherit')
  }
  html2canvas(DOM).then(canvas => {
    // 生成一个a元素
    const a = document.createElement('a')
    // 创建一个单击事件
    const event = new MouseEvent('click')
    a.download = name || 'photo'
    // 生成base64
    a.href = canvas.toDataURL('image/png')
    // 触发a的单击事件
    a.dispatchEvent(event)
    if (resetDOM) {
      DOM.style.overflow = 'auto'
      DOM.style.overflowX = 'hidden'
    }
  })
}

/**
* canvas加水印
* @method markCanvas
*
* @param  {HTMLElement}   text DOM选择器
* @return {HTMLElement}   返回  图片
*/
export const markCanvas = text => {
  const waterMarkText = text
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  canvas.width = 400
  canvas.height = 240
  ctx.textAlign = 'center'
  ctx.textBaseline = 'middle'
  ctx.globalAlpha = 0.1
  ctx.font = '25px 苹方-常规,微软雅黑'
  ctx.translate(100, 100)
  ctx.rotate(-Math.PI / 4)
  ctx.fillText(waterMarkText, 40, 0)
  return canvas
}

// 数字千分位格式化
export const toThousands = (num) => {
  return Number(num) ? Number(num).toLocaleString() : '0'
}

// 获取当前时间
export const getTime = () => {
  const myDate = new Date()
  const [y, m, d, h, t, s] = [
    myDate.getFullYear(),
    myDate.getMonth() + 1,
    myDate.getDate(),
    myDate.getHours(),
    myDate.getMinutes(),
    myDate.getSeconds()
  ]
  const time = `${y}${formatTime(m)}${formatTime(d)}${formatTime(h)}${formatTime(t)}${formatTime(s)}`
  return time
}
const formatTime = (time) => {
  return time > 9 ? time : `0${time}`
}

/**
 * 获取url参数
*/
export const getQueryVariable = (variable) => {
  var query = window.location.href.split('?')[1]
  if (!query) {
    return false
  }
  var vars = query.split('&')
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=')
    if (pair[0] === variable) { return pair[1] }
  }
  return false
}

export const getUsername = () => {
  const username = store.state.username
  const date = new Date()
  const month = `${date.getMonth() + 1}`
  const day = `${date.getDate()}`
  return `${username}${month.padStart(2, '0')}${day.padStart(2, '0')}`
}

export function formatNum (data, unit = '') {
  return data >= 100000000
    ? `${toThousands((data / 100000000).toFixed(2))}亿`
    : data >= 10000
      ? `${toThousands((data / 10000).toFixed(2))}万`
      : `${data}${unit}`
}
