<template>
  <el-popover
    ref="popover"
    placement="bottom-start"
    popper-class="category-cascader-popper"
    :show-arrow="false"
    @show="toggle"
    @hide="toggle"
    trigger="click"
  >
    <div class="category-header">类目选择</div>
    <searchVue
      v-if="!inputDisabled && search"
      :websiteId="websiteId"
      :searchValue="search"
      @onCategoryData="onCategoryData"
    ></searchVue>
    <category
      v-else
      :websiteId="websiteId"
      :defaultList="selectList"
      @onCategoryData="onCategoryData"
    ></category>
    <div class="category-footer">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit()">确认</el-button>
    </div>
    <template #reference>
      <div class="category-cascader" @click="visibleToggle">
        <div class="header">行业类目</div>
        <div class="category-input" :class="{'select-border': popover?.visibility}">
          <el-tooltip
            class="box-item"
            effect="dark"
            content="若重新搜索，请清空已选类目"
            placement="top"
            :disabled="!inputDisabled"
          >
            <el-input
              v-model="search"
              :disabled="inputDisabled"
              ref="inputSearch"
              placeholder="行业类目搜索"
              class="input-with-select"
              @blur="() => (isFocus = false)"
              @focus="() => (isFocus = true)"
            >
            <template v-slot:suffix>
              <span
                class="iconfont icon-lurukuang_xiala"
                :class="{'up': popover?.visibility}"
                @click="clear"
              ></span>
            </template>
            </el-input>
          </el-tooltip>
        </div>
      </div>
    </template>
  </el-popover>
</template>
<script>
import { defineComponent, ref, watch } from 'vue'
import category from './category.vue'
import searchVue from './search.vue'
import './category-cascader.scss'
export default defineComponent({
  components: { category, searchVue },
  emits: {
    onCategoryEvent: null
  },
  props: {
    websiteId: [String, Number]
  },
  setup (props, { emit }) {
    // 页面初始化获取数据
    const sessionData = JSON.parse(localStorage.getItem('category'))
    const selectList = ref(sessionData?.selectList || {})
    const excludeTime = ref({})

    const search = ref('')

    const searchName = ref(sessionData?.searchName || '')
    const onCategoryData = ({ list, time, name, isFirst }) => {
      selectList.value = list
      searchName.value = name
      excludeTime.value = time

      // 页面初始化提交一次
      isFirst && submit()
    }

    const inputSearch = ref(null)
    const clear = () => {
      search.value = ''
      inputDisabled.value = false
      inputSearch.value && inputSearch.value.focus()
      emit('onCategoryEvent', {
        fourth_category_ids: '',
        third_category_ids: '',
        second_category_ids: '',
        first_category_ids: ''
      }, {})
      localStorage.setItem('category', JSON.stringify({}))
    }

    const isFocus = ref(false)

    const popover = ref(null)
    const cancel = () => {
      popover.value && popover.value.hide()
    }
    const toggle = () => {
      if (popover.value) {
        if (!popover.value.visibility && isFocus.value) {
          popover.value.show()
        }
      }
    }

    // 页面初始化，如果输入框有东西禁止搜索
    const inputDisabled = ref(!!search.value)
    const submit = async () => {
      if (!Object.keys(selectList.value).length) return

      const { first, second, third, fourth } = selectList.value
      if (first?.length) {
        inputDisabled.value = true
      }
      const emitObj = {
        fourth_category_ids: fourth.join(','),
        third_category_ids: third.join(','),
        second_category_ids: second.join(','),
        first_category_ids: first.join(',')
      }
      emit('onCategoryEvent', emitObj, excludeTime.value)
      cancel()

      search.value = searchName.value

      localStorage.setItem(
        'category',
        JSON.stringify({
          selectList: selectList.value,
          searchName: searchName.value,
          excludeTime: excludeTime.value
        })
      )
    }
    sessionData && submit()

    watch(() => props.websiteId, clear)

    return {
      search,
      inputSearch,
      selectList,
      inputDisabled,
      onCategoryData,
      submit,
      isFocus,
      clear,
      popover,
      cancel,
      toggle
    }
  }
})
</script>
