<template>
  <div class="charts">
    <div class="charts-header">
      <slot name="header"></slot>
      <div class="header-btn">
        <el-tooltip
          effect="dark"
          content="折线图"
          placement="top"
        >
          <el-button
            icon="iconfont icon-zhexiantu"
            size="small"
            :class="{'select-btn': typeChart === 'line'}"
            @click="toggle('line')"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          effect="dark"
          content="柱图"
          placement="top"
        >
          <el-button
            icon="iconfont icon-tiaoxingtu"
            size="small"
            :class="{'select-btn': typeChart === 'bar'}"
            @click="toggle('bar')"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          effect="dark"
          content="饼图"
          placement="top"
        >
          <el-button
            icon="iconfont icon-bingtu"
            size="small"
            :class="{'select-btn': typeChart === 'pie'}"
            @click="toggle('pie')"
          ></el-button>
        </el-tooltip>
        <el-tooltip
          effect="dark"
          popper-class="table-search-tooltip"
          :content="
            modelValue.download_pms && !modelValue.download_pms.permission
              ? modelValue.download_pms.message
              : '下载'
          "
          placement="top"
        >
          <el-button
            icon="iconfont icon-xiazai"
            size="small"
            :class="
              {disabledBtn: !(modelValue.download_pms && modelValue.download_pms.permission)}
            "
            @click="download"
          ></el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="summary-number">
      <slot name="summary"></slot>
    </div>
    <noData v-show="noData" />
    <div class="chart-content" v-show="!noData" id="chart-content">
      <div v-if="typeChart === 'bar'" class="bar-title">
        <span class="bar-name">{{ barText }}</span>
        <chartTime :barTime="barTime"></chartTime>
      </div>
      <div v-if="typeChart === 'pie'" class="bar-title pie-title">
        <div v-if="selectTag !== 3">
          <span class="bar-name">销售金额占比图</span>
          <chartTime :barTime="barTime"></chartTime>
        </div>
        <div v-if="selectTag !== 2">
          <span class="bar-name">销售件数占比图</span>
          <chartTime :barTime="barTime"></chartTime>
        </div>
      </div>
      <div v-if="typeChart === 'bar'" class="bar-style">
        <div v-show="selectTag !== 2">
          <div class="tag"></div>
          销售件数
        </div>
        <div v-show="selectTag !== 3">
          <div class="tag"></div>
          销售金额
        </div>
      </div>
      <div id="chart" :class="{'bar': typeChart === 'bar'}"></div>
      <chartBottomFilter
        :lineNumber="lineNumber"
        :typeChart="typeChart"
        :option="modelValue"
      ></chartBottomFilter>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, nextTick, ref, watch } from 'vue'
import { screenShot, getTime } from '@/utils/index'
import * as echarts from 'echarts'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { lineData, pieData, barData, lineDataMore } from './charts-setup'
import chartBottomFilter from './chart-bottom-filter'
import { resetData, echartData } from './charts-condition-fn'
import noData from '@/components/no-data'
import watermark from '@/components/directives'
import chartTime from './chart-time.vue'
import { downloadEchartLog } from '@/utils/saveLog'

export default defineComponent({
  name: 'charts',
  components: { chartBottomFilter, noData, chartTime },
  props: {
    modelValue: {
      type: Object,
      default: () => {}
    },
    lineNumber: Number
  },
  setup (props) {
    const typeChart = ref('bar')
    let length = 0

    // 重构初始化数据
    watch([props.modelValue, typeChart], () => {
      resetData(props.modelValue)
    })

    // 监听重构数据
    watch(echartData, () => {
      chart(echartData, typeChart.value)
    })

    const toggle = (type) => {
      typeChart.value = type
    }

    // 图表的配置项
    const dataFn = { line: lineData, pie: pieData, bar: barData }
    const selectTag = ref(0)
    const chartsOption = (data, type, isZoom) => {
      if (typeChart.value === 'line') {
        if (data?.sales_amount?.data?.length && data?.sales_num?.data?.length) {
          myChart.resize({ height: '755px' })
          return lineDataMore(data, '', isZoom)
        }
      }
      selectTag.value =
        data.sales_amount_bar.length && data.sales_num_bar.length
          ? 1 : data.sales_amount_bar.length
            ? 2 : data.sales_num_bar.length
              ? 3 : 0

      if (type === 'bar') {
        const heightMul = 40
        myChart.resize({ height: `${length * heightMul}px` })
        // myChart.resize({ height: `${(length > 3 ? length : 3) * heightMul}px` })
        document.querySelector('#chart').style.overflowY = length <= 11 ? 'hidden' : 'auto'
        if (length <= 8) {
          document.querySelector('.bar').classList.add('reset-bar')
        } else {
          document.querySelector('.bar').classList.remove('reset-bar')
        }
      } else {
        myChart.resize({ height: '392px' })
      }

      return dataFn[type](data, isZoom)
    }

    // 创建图表
    let myChart
    const noData = ref(true)
    const barTime = ref('')
    const chart = (data, type, isZoom = true) => {
      // 加水印
      setTimeout(() => {
        watermark({ watermarl_element: 'chart-content' })
      }, 1000)
      if (
        !data?.sales_amount?.data?.length &&
        !data?.sales_amount_bar?.length &&
        !data?.sales_num?.data?.length &&
        !data?.sales_num_bar?.length
      ) {
        noData.value = true
        return
      }
      noData.value = false
      nextTick(() => {
        myChart && myChart.dispose()
        const chartDom = document.getElementById('chart')
        myChart = echarts.init(chartDom)

        if (data?.sales_amount_bar?.length || data?.sales_num_bar?.length) {
          length = data.sales_amount_bar.length || data.sales_num_bar.length
        }
        barTime.value = `${data.time_origin.firatDay}-${data.time_origin.lastDay}`
        const option = chartsOption(data, type, isZoom)
        option && myChart.setOption(option)
      })
    }
    const route = useRoute()
    const download = () => {
      const chartName = {
        line: '折线图',
        bar: '柱图',
        pie: '占饼图'
      }
      if (props.modelValue.download_pms?.permission) {
        screenShot('.chart-content', false, `${route.meta.title}-${chartName[typeChart.value]}${getTime()}`)
        downloadEchartLog()
      }
    }
    // 监听菜单的状态
    const store = useStore()
    const state = store.state
    watch(
      () => state.isShowMenu,
      (val) => {
        if (!myChart) return

        // 获取当前图表的宽度
        const oldWidth = myChart.getWidth()
        let width = ''
        width = !val ? oldWidth + 200 + 'px' : oldWidth - 200 + 'px'
        myChart.resize({ width })
      }
    )

    const barText = computed(() => {
      return selectTag.value === 1 ? '销售金额/件数差距图' : selectTag.value === 2 ? '销售金额差距图' : '销售件数差距图'
    })
    return {
      toggle,
      download,
      typeChart,
      noData,
      barText,
      barTime,
      selectTag
    }
  }
})
</script>

<style lang="scss">
.charts {
  margin-top: 96px;
  border-radius: 4px;
  padding: 24px 0 0;
  background: #fff;
  &>div:not(.chart-content) {
    padding: 0 24px;
  }
  .disabledBtn, .disabledBtn:hover  {
    cursor: no-drop;
    border-color: #D0D3D9 !important;
    background: #F7F8FA !important;
    i {
      color: #84868C !important;
    }
  }
  .charts-header {
    display: flex;
    justify-content: space-between;
    background: #fff;
    margin-bottom: 16px;
    div {
      display: flex;
      align-items: center;
    }
    .header-btn {
      button {
        margin-left: -1px;
        border-radius: 0;
        width: 48px;
        height: 36px;
        i {
          color: #b4b7bf;
        }
        &:hover {
          border-color: #f04848;
          background: rgb(255, 246, 246);
          z-index: 1;
          i {
            color: #f04848;
          }
        }
        &:focus {
          border: 1px solid #f04848;
          background: rgb(255, 246, 246);
          i {
            color: #f04848;
          }
        }
        &:nth-child(1) {
          border-radius: 2px 0 0 2px;
        }
        &:nth-child(3) {
          border-radius: 0 2px 2px 0;
        }
        &:last-child {
          width: 36px;
          padding: 0;
          margin-left: 16px;
          border-radius: 2px;
          i {
            color: #242426;
          }
        }
      }
      .select-btn {
        border: 1px solid #f04848 !important;
        background: #fff6f6 !important;
        z-index: 1;
        i {
          color: #f04848;
        }
      }
    }
  }
  .summary-number {
    display: flex;
    & > div {
      width: calc(100% / 2 - 8px);
    }
    & > div:first-child {
      margin-right: 16px;
    }
  }
  .chart-content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px 12px;
    .bar-style {
      font-size: 12px;
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &>div {
        display: flex;
        align-items: center;
        height: 17px;
        color: #545559;
      }
      .tag {
        width: 8px;
        height: 8px;
        border-radius: 2px;
        margin-right: 12px;
      }
      &>div:last-child .tag {
        background-image: linear-gradient(0deg, #FAD282 0%, #FFCAA2 100%);
      }
      &>div:first-child .tag {
        background-image: linear-gradient(180deg, #8BABFF 0%, #9894FF 100%);
      }
    }
  }
  #chart {
    // 图表不设置默认高度控制台会有警告
    min-width: 1px;
    min-height: 1px;
    flex: 1;
    background-color: #fff;
    text-align: left;
    overflow-x: hidden;
    overflow-y: hidden !important;
    div {
      span:first-child {
        overflow: hidden;
        float: left;
        width: 8px !important;
        height: 8px !important;
        border-radius: 2px !important;
        margin-top: 2px;
      }
      span:nth-child(2) {
        float: left;
      }
    }
    &>div:last-child:not(.mask_div) {
      display: none;
      border-color: #fff !important;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.12) !important;
      padding: 12px !important;
    }
    .echart-line {
      .header {
        line-height: 17px;
        font-weight: 500;
      }
      .line-name {
        display: flex;
        align-items: center;
        margin-top: 8px;
        font-size: 12px;
        line-height: 12px;
      }
      .tag {
        margin-top: 0 !important;
        margin-right: 4px;
        font-size: 12px;
      }
    }
  }
  .bar-title {
    margin-top: 25px;
    text-align: left;
    display: flex;
    .bar-name {
      color: rgba(36, 36, 38, 1);
      font-size: 14px;
    }
  }
  .pie-title>div{
    display: flex;
    &:first-child {
      margin-right: 210px;
    }
  }
  #chart.bar {
    margin-top: 18px;
    margin-bottom: 24px;
    max-height: 566px;
    overflow-x: hidden;
    overflow-y: auto !important;
    // &>div {
    //   max-height: 566px;
    //   overflow: auto;
    //   overflow-x: hidden;
    // }
  }
  #chart.reset-bar {
    overflow-x: inherit !important;
    overflow-y: inherit !important;
  }
}
.echart-popup {
  z-index: 1001 !important;
  &>div {
    &>div>div:first-child {
      font-weight: 500 !important;
      color: #242426 !important;
      line-height: 17px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 346px
    }
  }
  &>div>div:not(:first-child) {
    span {
      color: #242426 !important;
      font-weight: 400 !important;
    }
    span:nth-child(2) {
      &:after {
        content: "\ff1a"
      }
    }
    span {
      margin-left: 0 !important;
      font-size: 12px !important;
      float: left !important;
    }
    span:first-child {
      margin-right: 12px !important;
    }
    &>div:not(:first-child) {
      margin-top: 8px !important;
      margin-bottom: 0 !important;
      &:last-child {
        margin-top: 0 !important;
      }
    }
  }
}
</style>
