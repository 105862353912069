<template>
  <div class="category-content" v-loading="loading">
    <div
      class="category-box"
      v-for="(key, index) of prevKeys"
      :key="key"
      :class="{'box-hide': !list[key].length}"
    >
      <el-checkbox
        v-model="selectAll[key]"
        @change="setSelectAll($event, key)"
        :indeterminate="selectAll[`${key}Indeterminate`]"
        label="全部"></el-checkbox>
      <el-checkbox-group v-model="selectList[key]" @change="change($event, key)">
        <el-checkbox
          v-for="li of list[key]"
          :key="li.id"
          :label="li.id"
          :disabled="li.is_permission === 0"
        >
          <div>{{li.name}}</div>
          <!-- 最后一列取消箭头 -->
          <span class="iconfont icon-jiantou_xiangyou" v-if="index !== prevKeys.length-1"></span>
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <div class="no-data" v-if="isShowNoData">
      <img src="@/assets/no-data/no-data.png">
      <span>{{ noDataText }}</span>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, computed, watch, nextTick } from 'vue'
import axios from '@/utils/request'
export default defineComponent({
  emits: {
    onCategoryData: null
  },
  props: {
    websiteId: [String, Number],
    defaultList: Object
  },
  setup (props, { emit }) {
    const prevKeys = computed(() => {
      return props.websiteId === 71 ? ['first', 'second', 'third', 'fourth'] : ['first', 'second', 'third']
    })
    const selectList = reactive({ first: [], second: [], third: [], fourth: [] })

    // 重置数据列表
    const resetData = (key) => {
      // 创建一个链表
      const fo = { key: 'fourth' }
      const t = { key: 'third', next: fo }
      const s = { key: 'second', next: t }
      let reflect = { key: 'first', next: s }

      let status = false
      while (reflect) {
        if (reflect.key === key) {
          status = true
        } else if (status) {
          list[reflect.key] = []
          selectList[reflect.key] = []
          selectAll[reflect.key] = false
          selectAll[`${reflect.key}Indeterminate`] = false
        }
        reflect = reflect.next
      }
    }

    // 全选或取消全选触发
    const setSelectAll = (status, key) => {
      selectList[key] = status ? list[key].filter(obj => obj.is_permission !== 0).map(obj => obj.id) : []
      change(selectList[key], key)
    }

    const excludeTime = ref({})
    const change = (list, key) => {
      excludeTime.value = timePermission[key]
      if (list.length === 1) {
        params[`${key}_id`] = list[0]
        getData(key)
      } else {
        resetData(key)
      }
      selectAllStatus(key)
    }

    // 存储当前全选按钮选中半选状态
    const selectAll = reactive({
      first: false,
      firstIndeterminate: false,
      second: false,
      secondIndeterminate: false,
      third: false,
      thirdIndeterminate: false,
      fourth: false,
      fourthIndeterminate: false
    })
    const selectAllStatus = (key) => {
      const currentPermissionList = list[key].filter(obj => obj.is_permission !== 0)
      // 当前有权限数据跟选中数据数量一样，状态为全选
      selectAll[key] = selectList[key].length === currentPermissionList.length
      // 当前有选中且不是全选，将状态置为半选
      selectAll[`${key}Indeterminate`] = Boolean(selectList[key].length && !selectAll[key])
    }

    let params = { website_id: props.websiteId, first_id: '', second_id: '', third_id: '' }
    const resetParams = (key) => {
      const param = {}
      const paramKey = {
        first: ['first_id'],
        second: ['first_id', 'second_id'],
        third: ['first_id', 'second_id', 'third_id'],
        fourth: []
      };
      ['first_id', 'second_id', 'third_id', 'fourth_id'].forEach(k => {
        param[k] = key && paramKey[key].includes(k) ? params[k] : ''
      })
      return { ...params, ...param }
    }

    const timePermission = {}
    const list = reactive({ first: [], second: [], third: [], fourth: [] })
    const loading = ref(false)
    const getData = (key) => {
      return new Promise((resolve) => {
        if (key === prevKeys.value[prevKeys.value.length - 1]) {
          resolve({})
          return
        }

        const params = resetParams(key)
        loading.value = true
        axios.get('/api/v1/basedata/get_categories', { params }).then((res) => {
          if (res && res.error_code === 0) {
            const { categories, start_date: start, end_date: end } = res.data
            const keyReflect = { first: 'second', second: 'third', third: 'fourth' }
            list[keyReflect[key] || 'first'] = categories.filter(({ id }) => id)
            timePermission[keyReflect[key] || 'first'] = { start, end }
            // 重置全选半选按钮状态
            selectAllStatus(keyReflect[key] || 'first')
            resolve(res.data)
          }
        }).finally(() => {
          loading.value = false
        })
      })
    }

    watch(() => props.websiteId, () => {
      Object.keys(selectList).forEach(key => (selectList[key] = []))
      Object.keys(list).forEach(key => (list[key] = []))
      params = { website_id: props.websiteId, first_id: '', second_id: '', third_id: '' }
      getData()
    })

    const getEmitName = () => {
      const nameList = []
      let name = ''
      Object.entries(selectList).forEach(([key, value]) => {
        value[0] === -9 && (value = [])
        if (value.length) {
          nameList.length = 0
          list[key].forEach(obj => {
            if (value.includes(obj.id)) {
              nameList.push(`${name}${obj.name}`)
              value.length === 1 && (name = name + obj.name + '-')
            }
          })
        }
      })
      return nameList.join('/')
    }

    // list改变触发是为了生成name，否则数据不足，name初始化会变成空
    watch([selectList, list], () => {
      nextTick(() => {
        emit('onCategoryData', { list: selectList, time: excludeTime.value, name: getEmitName() })
      })
    })

    // 页面初始化,准备初始化屏蔽时间需要使用的key
    const createKeys = ['']
    Object.entries(props.defaultList).forEach(([key, value]) => {
      if (value.length) {
        selectList[key] = value
        if (value.length === 1) {
          params[`${key}_id`] = value[0]
          createKeys.push(key)
        }
      }
    })
    Promise.all(createKeys.map(getData)).then(res => {
      // 根据当前选中数组判断取第几项的value
      Object.entries(selectList).forEach(([_, value], i) => {
        if (value.length) {
          const { start_date: start, end_date: end } = res[i]
          excludeTime.value = { start, end }
        }
      })

      // 页面初始化重置name
      emit('onCategoryData', { list: selectList, time: excludeTime.value, name: getEmitName(), isFirst: true })
    })

    const isShowNoData = computed(() => {
      const showDataList = Object.values(list).filter(v => v.length)
      return showDataList.length !== prevKeys.value.length
    })

    const noDataText = computed(() => {
      const noNextData = Object.values(selectList).some(v => v.length > 1)
      return noNextData ? '上级类目多选后无法查看子类目' : '无下级数据'
    })

    return {
      change,
      selectList,
      selectAll,
      setSelectAll,
      list,
      loading,
      isShowNoData,
      noDataText,
      prevKeys
    }
  }
})
</script>
