<template>
  <div class="navigation-top-container">
    <div type="flex" align="middle" :class="{'box-shandow': boxShandow}">
      <div class="navigation-top-search-box">
        <div class="navigation-top-row">
          <selectDropdown
            v-if="!isShow.includes('selectDropdownFocus')"
            @onSelectDropdownEvent="onSelectDropdownEvent"
            :startTitle="startTitleNavi"
            :endTitle="endTitleNavi"
            :placeholder="placeholderNavi"
          />
          <selectDropdownFocus
            v-if="isShow.includes('selectDropdownFocus')"
            @onSelectDropdownFocus="onSelectDropdownFocus"
            :brandType="brandType"
            :startTitle="startTitleNavi"
          />
          <categoryCascader
            v-if="isShow.includes('categoryCascader')"
            @onCategoryEvent="onCategoryEvent"
            :websiteId="naviParams.platform_id"
          />
          <datePicker
            :websiteId="naviParams.platform_id"
            :excludeTime="excludeTime"
            @onDateValueEvent="onDateValueEvent"
          />
        </div>
      </div>
      <div
        class="navigation-top-setup"
        v-if="isShowSetting || isShopDetail">
        <shopDetail
          v-if="isShopDetail"
          :shopDetail="shopDetail"
        ></shopDetail>
        <brandSettings
          v-if="isShow.includes('brandSettings')"
          :websiteId="naviParams.platform_id"
          :brandType="brandType"
          :model="naviParams.model"
          :isRead="isRead"
          @onBrandSettingsEvent="onBrandSettingsEvent"
        />
        <topBrandRadio
          v-if="isShow.includes('topBrandRadio')"
          :brandType="brandType"
          :brandIds="naviParams.brand_ids"
          @onTopBrandRadioEvent="onTopBrandRadioEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed, watch } from 'vue'
import selectDropdown from '@/components/select-dropdown.vue'
import selectDropdownFocus from '@/components/select-dropdown-focus.vue'
import datePicker from '@/components/date-picker.vue'
import categoryCascader from '@/components/category/category-cascader.vue'
import brandSettings from '@/components/brand-settings.vue'
import topBrandRadio from '@/components/top-brand-radio.vue'
import shopDetail from '@/components/shop-detail.vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'NavigationTop',
  components: {
    datePicker,
    selectDropdown,
    selectDropdownFocus,
    categoryCascader,
    brandSettings,
    topBrandRadio,
    shopDetail
  },
  props: {
    visibleOption: {
      type: Array,
      default: () => []
    },
    brandType: {
      type: String,
      default: ''
    },
    selectOptions: {
      type: Array,
      default: () => []
    },
    startTitle: {
      type: String,
      default: '头文案'
    },
    endTitle: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    searchOptions: {
      type: Array,
      default: () => []
    },
    categoryOptions: {
      type: Array,
      default: () => []
    },
    isRead: {
      type: Boolean,
      default: true
    }
  },
  emits: {
    onNaviEvent: null,
    setTitle: null
  },
  setup (props, context) {
    const hash = window.location.hash
    const isShow = reactive(props.visibleOption)
    const store = useStore()
    const phone = store.state.phone
    const dataValue = JSON.parse(localStorage.getItem(`${phone}_date`))
    const platformId =
      hash.endsWith('/shop/detail') || hash.endsWith('/brand/detail')
        ? Number(localStorage.getItem(`${phone}_${props.brandType}_dropdown`))
        : Number(localStorage.getItem(`${phone}_dropdown`))
    const ids = JSON.parse(localStorage.getItem(`${phone}_cascader_ids`))
    const brandIdLocal = localStorage.getItem(`${phone}_${props.brandType}_setting`)
    const firstNameLocal = localStorage.getItem(`${phone}_${props.brandType}_firstName`)
    const secondNameLocal = localStorage.getItem(`${phone}_${props.brandType}_secondName`)

    const naviParams = reactive({
      start_time: dataValue ? dataValue[0] : '',
      end_time: dataValue ? dataValue[1] : '',
      platform_id: platformId || '',
      first_category_ids: ids ? ids.first_category_ids : '',
      second_category_ids: ids ? ids.second_category_ids : '',
      third_category_ids: ids ? ids.third_category_ids : '',
      fourth_category_ids: ids ? ids.fourth_category_ids : '',
      brand_ids: brandIdLocal || '',
      model: 'all'
    })

    // 时间选择器相关
    const onDateValueEvent = (e) => {
      naviParams.start_time = e[0]
      naviParams.end_time = e[1]
      context.emit('onNaviEvent', naviParams)
    }

    // 下拉框相关
    const startTitleNavi = ref(props.startTitle)
    const endTitleNavi = ref(props.endTitle)
    const placeholderNavi = ref(props.placeholder)
    const onSelectDropdownEvent = (e) => {
      naviParams.platform_id = e
      onCategoryEvent({ first_category_ids: '', second_category_ids: '', third_category_ids: '', fourth_category_ids: '' }, {})
      context.emit('onNaviEvent', naviParams)
    }

    // 品牌关注详情下拉相关
    const shopDetail = reactive(
      firstNameLocal && secondNameLocal
        ? { firstName: firstNameLocal, secondName: secondNameLocal }
        : {}
    )

    context.emit('setTitle', shopDetail)
    watch(shopDetail, () => {
      context.emit('setTitle', shopDetail)
    })

    const onSelectDropdownFocus = (e) => {
      naviParams.platform_id = e.platform_id
      naviParams.brand_ids = e.brand_ids
      shopDetail.firstName = e.firstName
      shopDetail.secondName = e.secondName
      context.emit('onNaviEvent', naviParams)
    }

    // 类目级联相关
    const excludeTime = reactive({})
    const onCategoryEvent = (e, time) => {
      naviParams.first_category_ids = e.first_category_ids
      naviParams.second_category_ids = e.second_category_ids
      naviParams.third_category_ids = e.third_category_ids
      naviParams.fourth_category_ids = e.fourth_category_ids
      excludeTime.start = time.start
      excludeTime.end = time.end
      context.emit('onNaviEvent', naviParams)
    }

    // 品牌关注设置相关
    const onBrandSettingsEvent = (e) => {
      naviParams.brand_ids = e
      context.emit('onNaviEvent', naviParams)
    }

    // Top关注品牌相关
    const onTopBrandRadioEvent = (e) => {
      naviParams.model = e
      context.emit('onNaviEvent', naviParams)
    }

    onMounted(() => {
      context.emit('onNaviEvent', naviParams)
    })

    watch(naviParams, () => store.commit('clearcloseArr'))
    const boxShandow = ref(window.scrollY > 0)
    window.onscroll = () => {
      boxShandow.value = window.scrollY > 0
      const scrollY = window.scrollY > 64 ? 64 : window.scrollY
      document.querySelector('.navigation-top-container>div') &&
        (document.querySelector('.navigation-top-container>div').style.marginTop = `-${scrollY}px`)
      document.querySelector('.menu-container') &&
        (document.querySelector('.menu-container').style.marginTop = `-${scrollY}px`)
      document.querySelector('.navi-container') &&
        (document.querySelector('.navi-container').style.opacity = 1 - scrollY / 64)
    }

    const isShowSetting = computed(
      () => isShow.includes('brandSettings') || isShow.includes('topBrandRadio')
    )
    const isShowShopDetail = computed(
      () => Object.keys(shopDetail).length
    )
    const isShopDetail = computed(() => isShowShopDetail.value && isShow.includes('selectDropdownFocus'))
    return {
      isShow,
      isShopDetail,
      isShowSetting,
      naviParams,
      excludeTime,
      startTitleNavi,
      endTitleNavi,
      placeholderNavi,
      boxShandow,
      shopDetail,
      onCategoryEvent,
      onDateValueEvent,
      onBrandSettingsEvent,
      onSelectDropdownEvent,
      onSelectDropdownFocus,
      onTopBrandRadioEvent
    }
  }
})
</script>

<style lang="scss">
.box-shandow {
  box-shadow: 0 2px 8px 0 rgba(0,0,0,0.12);
}
.navigation-top-container {
  position: fixed;
  z-index: 999;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  &>div {
    width: 997px;
    margin-left: 204px;
    border-radius: 4px;
    overflow: hidden;
  }
}
.navigation-top-search-box {
  padding: 22px 24px;
  background-color: #e9d8bb;

  .navigation-top-row {
    display: flex;
    height: 36px;
  }
}
.navigation-top-setup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background: #fff;
  .el-button {
    padding: 8px 13px;
  }
}
</style>
