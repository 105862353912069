<template>
<div class="select-dropdown-container reset-header">
  <div class="header">{{ start }}</div>
  <el-select
    size="medium"
    popper-class="select-dropdown"
    v-model="value"
    @change="onChange"
    :placeholder="placeholder">
    <el-option
      v-for="item in selectOption"
      :key="item.id"
      :label="item.name"
      :value="item.id">
    </el-option>
  </el-select>
  <span class="select-dropdown-end-title">{{ end }}</span>
</div>
</template>

<script>
import { defineComponent, nextTick, ref } from 'vue'
import axios from '@/utils/request'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'SelectDropdown',
  props: {
    startTitle: {
      type: String,
      default: '头文案'
    },
    endTitle: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    }
  },
  emits: {
    onSelectDropdownEvent: null
  },
  setup (props, context) {
    const store = useStore()
    const phone = store.state.phone
    const top = localStorage.getItem(`${phone}_dropdown`)
      ? Number(localStorage.getItem(`${phone}_dropdown`))
      : ''
    const value = ref('')
    const start = ref(props.startTitle)
    const end = ref(props.endTitle)
    const selectOption = ref([])

    axios.get('/api/v1/basedata/get_website').then(res => {
      if (res && res.error_code === 0) {
        selectOption.value = res.data
        nextTick(() => {
          value.value = top
        })
      }
    })

    const onChange = val => {
      localStorage.setItem(`${phone}_dropdown`, val)
      const selectObj = selectOption.value.filter(s => s.id === val)[0] || {}
      localStorage.setItem(`${phone}_dropdown_name`, selectObj.name || '')
      context.emit('onSelectDropdownEvent', val)
    }

    return {
      value,
      selectOption,
      start,
      end,
      onChange
    }
  }
})
</script>
<style lang="scss">
  .select-dropdown.el-popper  {
    z-index: 1000 !important;
  }
  .select-dropdown-container {
    display: flex;
    .el-select {
      width: 106px;
    }
    input {
      background: #FBF8F3;
    }
  }
</style>
