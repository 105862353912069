<template>
  <div :class="[path.name=== '/login'?'app-container-content':'app-container']">
    <navigationBar />
    <div class="main-container">
      <div class="min-box-router-view" :class="{'user-back': isUserInfo}">
        <menuLeft v-if="state.isLogin" />
        <router-view class="router-view" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted, watch, computed } from 'vue'
import { useStore } from 'vuex'
import menuLeft from '@/components/navigation-menu.vue'
import navigationBar from '@/components/navigation-bar.vue'

export default defineComponent({
  name: 'App',
  components: {
    menuLeft,
    navigationBar
  },
  setup () {
    const { state } = reactive(useStore())
    const path = reactive({
      name: ''
    })
    onMounted(() => {
      path.name = state.nowUrl
    })
    watch(() => state.nowUrl, (newVal, oldVal) => {
      path.name = newVal
    })

    const isUserInfo = computed(() => {
      const pathName = ['/person', '/settings']
      return pathName.includes(path.name)
    })

    return {
      state, path, isUserInfo
    }
  }
})
</script>

<style lang="scss">
@import './style/global.scss';
html {
  height: 100%;
}
body, #app, .app-container {
  height: 100%;
  box-sizing: border-box;
}
.app-container {
  position: relative;
  padding-top: 64px;
  // overflow: hidden;
}
.app-container-content{
  position: relative;
  overflow: hidden;
}
.main-container {
  display: flex;
  justify-content: center;
  background: #f7f8fa;
  min-height: 100%;
  .min-box-router-view {
    min-width: 1146px;
    display: flex;
    width: 100%;
    justify-content: center;
    background: #f6f6f9 url("~@/assets/top-bar/bar-bottom.png") no-repeat;
    background-size: 100% auto;
    .router-view {
      width: 997px;
      margin-left: 204px;
    }
  }
  .user-back {
    background: #f6f6f9 url("~@/assets/top-bar/bar-bottom.png") no-repeat;
    background-size: 100% auto;
  }
}
</style>
