import { getUsername } from '@/utils/index'
export default function watermark (settings) {
  // 默认设置
  const defaultSettings = {
    tag: 'id',
    watermarl_element: '',
    watermark_txt: getUsername(),
    watermark_x: 20, // 水印起始位置x轴坐标
    watermark_y: 20, // 水印起始位置Y轴坐标
    watermark_rows: 2000, // 水印行数
    watermark_cols: 2000, // 水印列数
    watermark_x_space: 0, // 水印x轴间隔
    watermark_y_space: 30, // 水印y轴间隔
    watermark_color: '#242426', // 水印字体颜色
    watermark_alpha: 0.06, // 水印透明度
    watermark_fontsize: '12px', // 水印字体大小
    watermark_font: '苹方-常规,微软雅黑', // 水印字体
    watermark_width: 200, // 水印宽度
    watermark_height: 160, // 水印长度
    watermark_angle: 45// 水印倾斜度数
  }
  for (const key in settings) {
    if (defaultSettings[key] && settings[key]) {
      defaultSettings[key] = settings[key]
    }
  }
  // 采用配置项替换默认值，作用类似jquery.extend
  if (arguments.length === 1 && typeof arguments[0] === 'object') {
    const src = arguments[0] || {}
    for (const key in src) {
      if (src[key] && defaultSettings[key] && src[key] === defaultSettings[key]) { continue } else if (src[key]) { defaultSettings[key] = src[key] }
    }
  }

  if (defaultSettings.tag === 'id') {
    const maskElement = document.getElementById(defaultSettings.watermarl_element)
    appendWater(maskElement, defaultSettings)
  } else {
    const maskElement = document.getElementsByClassName(defaultSettings.watermarl_element)
    Array.from(maskElement).forEach(e => appendWater(e, defaultSettings))
  }
}
const appendWater = (maskElement, defaultSettings) => {
  maskElement.querySelectorAll('.mask_div').forEach(e => e.remove())
  maskElement.style.position = 'relative'
  const oTemp = document.createDocumentFragment()
  // 获取页面最大宽度
  const pageWidth = Math.max(maskElement.scrollWidth, maskElement.clientWidth)

  // 获取页面最大高度
  const pageHeight = Math.max(maskElement.scrollHeight, maskElement.clientHeight)

  // 水印数量自适应元素区域尺寸
  defaultSettings.watermark_cols = Math.ceil(pageWidth / (defaultSettings.watermark_x_space + defaultSettings.watermark_width))
  defaultSettings.watermark_rows = Math.ceil(pageHeight / (defaultSettings.watermark_y_space + defaultSettings.watermark_height + 10))
  let x
  let y
  for (let i = 0; i < defaultSettings.watermark_rows; i++) {
    y = defaultSettings.watermark_y + (defaultSettings.watermark_y_space + defaultSettings.watermark_height) * i
    for (let j = 0; j < defaultSettings.watermark_cols; j++) {
      x = defaultSettings.watermark_x + (defaultSettings.watermark_width + defaultSettings.watermark_x_space) * j
      const mask_div = document.createElement('div')
      mask_div.id = 'mask_div' + i + j
      mask_div.className = 'mask_div'
      mask_div.innerHTML = (defaultSettings.watermark_txt)
      // 设置水印div倾斜显示
      mask_div.style.webkitTransform = 'rotate(-' + defaultSettings.watermark_angle + 'deg)'
      mask_div.style.MozTransform = 'rotate(-' + defaultSettings.watermark_angle + 'deg)'
      mask_div.style.msTransform = 'rotate(-' + defaultSettings.watermark_angle + 'deg)'
      mask_div.style.OTransform = 'rotate(-' + defaultSettings.watermark_angle + 'deg)'
      mask_div.style.transform = 'rotate(-' + defaultSettings.watermark_angle + 'deg)'
      mask_div.style.visibility = ''
      mask_div.style.position = 'absolute'
      mask_div.style.left = x + 'px'
      mask_div.style.top = y + 'px'
      mask_div.style.overflow = 'hidden'
      mask_div.style.zIndex = '99'
      mask_div.style.pointerEvents = 'none'// pointer-events:none  让水印不遮挡页面的点击事件
      mask_div.style.opacity = defaultSettings.watermark_alpha
      mask_div.style.fontSize = defaultSettings.watermark_fontsize
      mask_div.style.fontFamily = defaultSettings.watermark_font
      mask_div.style.color = defaultSettings.watermark_color
      mask_div.style.textAlign = 'center'
      // mask_div.style.width = defaultSettings.watermark_width + 'px'
      // mask_div.style.height = defaultSettings.watermark_height + 'px'
      mask_div.style.display = 'block'
      mask_div.style.padding = i ? '30px 70px' : '10px 70px 30px'
      oTemp.appendChild(mask_div)
    };
  };
  const waterBox = document.createElement('div')
  waterBox.style.position = 'absolute'
  waterBox.style.inset = '0'
  waterBox.style.display = 'block'
  waterBox.style.overflow = 'hidden'
  waterBox.style.pointerEvents = 'none'// pointer-events:none  让水印不遮挡页面的点击事件
  waterBox.style.boxShadow = 'inherit'// pointer-events:none  让水印不遮挡页面的点击事件

  waterBox.appendChild(oTemp)
  maskElement.appendChild(waterBox)
}
