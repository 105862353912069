<template>
  <div class="navigation-bar" :class="{'navigation-bar-white': path.name === '/login'}">
    <div class="navi-container">
      <div :class="[path.name === '/login' ? 'navi-title-white' : 'navi-title']" @click="goHome">
        <img v-if="path.name === '/login'" src="../assets/background/logo2.svg" />
        <img v-else src="../assets/background/logo1.svg" />
        <h2>消费洞察平台</h2>
      </div>
      <div v-if="state.isLogin" class="navi-content">
        <div class="jump-index" @click="goHome" v-if="state.menuType === 2">
          <span class="iconfont icon-dinglan_shouye"></span>
          首页</div>
        <div class="navi-content-username" @click="goUser">
          <span>{{ state.username }}</span>
        </div>
        <div v-show="path.name !== '/login'" class="navi-content-validuntil">
          <span>账号有效期至：</span>
          <span>{{ state.validityTime }}</span>
        </div>
        <div class="navi-content-quit" @click="quit">
          <span class="iconfont icon-dinglan_tuichu"></span>
          <span>退出</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'NavigationBar',
  setup () {
    const { state, dispatch } = reactive(useStore())
    const router = reactive(useRouter())
    const { proxy: { $confirm } } = getCurrentInstance()

    // 退出登录 变更Store中isLogin的状态
    const quit = () => {
      $confirm('', '是否确认退出？', {
        confirmButtonText: '确定',
        confirmButtonType: '',
        cancelButtonText: '取消',
        customClass: 'closeBox'
      }).then(() => {
        dispatch('quit', false).then(msg => {
          router.push('/login')
          ElMessage({
            message: msg,
            type: 'success',
            duration: 2 * 1000
          })
        })
      })
    }
    const path = reactive({
      name: ''
    })
    onMounted(() => {
      path.name = state.nowUrl
    })
    watch(() => state.nowUrl, (newVal, oldVal) => {
      path.name = newVal
    })
    // 返回首页
    const goHome = () => {
      if (window.location.hash.endsWith('login')) return
      router.push('/industry/rank')
    }
    // 跳转至用户中心
    const goUser = () => {
      router.push('/person')
    }
    return {
      path,
      state,
      quit,
      goHome,
      goUser
    }
  }
})
</script>

<style lang="scss" scoped>
.navigation-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  // padding-left: 24px;
  background: #28282F url("~@/assets/top-bar/bar-top.png") no-repeat left top;
  background-size: 100% 100%;
  height: 64px;
  align-items: center;
}

.navigation-bar-white {
  justify-content: flex-start;
  padding-left: 24px;
  background: #fff;
  height: 64px;
}

.navi-container {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 76px;

  .navi-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    img {
      width: 71px;
      height: 26px;
    }

    h2 {
      height: 16px;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      border-left: 1px solid #B4B7BF;
      padding-left: 12px;
      margin-left: 16px;
      line-height: 16px;
      font-family: PingFangSC-Regular;
    }
  }

 .navi-title-white {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    img {
      width: 71px;
      height: 26px;
    }

    h2 {
      height: 16px;
      color: #242426;
      font-size: 16px;
      font-weight: normal;
      border-left: 1px solid #E9ECF2;
      padding-left: 12px;
      margin-left: 16px;
      line-height: 18px;
    }
  }

  .navi-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .jump-index {
      color: #fff;
      margin-right: 16px;
      padding-right: 24px;
      border-right: 1px solid #B4B7BF;
      line-height: 16px;
      font-size: 14px;
      cursor: pointer;
      span {
        font-size: 14px;
        color: #fff;
      }
    }

    .navi-content-validuntil {
      margin-left: 8px;
      padding: 3px 8px;
      background: #484951;
      border-radius: 2px;
      margin-right: 24px;

      span {
        color: #fff;
        font-size: 12px;
      }
    }

    .navi-content-quit {
      cursor: pointer;
      padding-left: 17px;
      border-left: 1px solid #B4B7BF;
      line-height: 16px;

      span {
        color: #fff;
        font-size: 14px;
      }

      .icon-dinglan_tuichu {
        margin-right: 9px;
      }
    }

    .navi-content-username {
      cursor: pointer;
      span {
        color: #fff;
      }
    }
  }
}

</style>
<style lang="scss">
.closeBox {
  width: 281px !important;
  padding-bottom: 16px !important;
  .el-message-box__title {
    display: flex;
    align-items: center;
    font-family: "iconfont" !important;
    span {
      font-size: 14px;
      line-height: 20px;
      color: #242426;
    }
    &:before {
      font-size: 16px;
      content: "\e6d1";
      color: #b4b7bf;
      margin-right: 7px;
    }
  }
  .el-message-box__content {
    padding: 0;
  }
  .el-message-box__btns {
    display: flex;
    justify-content: space-between;
    padding-top: 28px;
    button {
      width: 120px;
      span {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
</style>
