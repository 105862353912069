export default function tableFirstHeaderScroll () {
  if (!document?.querySelector('.is-group')?.children[0]?.querySelectorAll('.is-left')) {
    return
  }

  // 存一下 一级标题盒子宽度以及里面的文字宽度
  document.querySelectorAll('.el-table__header-wrapper').forEach(e => {
    let headerNode = [...(e?.querySelector('.is-group')?.children[0]?.querySelectorAll('.is-left') || [])]
    headerNode = headerNode.filter(e => e.className.indexOf('is-hidden') === -1)

    if (!headerNode.length) return

    const nodeWidth = []
    const textWidth = []
    headerNode.forEach((e, i) => {
      textWidth.push(getTextWidth(e.querySelector('.cell').textContent))
      nodeWidth.push((nodeWidth[i - 1] || 0) + e.offsetWidth)
    })

    e.onscroll = () => {
      let index = 0
      const tableScrollLeft = e.scrollLeft
      nodeWidth.forEach((w, i) => {
        if (tableScrollLeft > w) (index = i + 1)
      })
      const elStyle = headerNode[index]?.querySelector('.cell')?.style
      if (!elStyle) return
      // 判断当前元素增加20px偏移量是否仍在滚动状态
      const isScroll = tableScrollLeft + textWidth[index] + 20 < nodeWidth[index]
      // 获取当前滚动量
      const getCurrentScroll = index ? tableScrollLeft - nodeWidth[index - 1] : tableScrollLeft
      elStyle.paddingLeft = `${isScroll ? getCurrentScroll + 12 : 12}px`
      resetDomLeft(headerNode, index)
    }
  })
}
let resetDomTimeout = ''
const resetDomLeft = (headerNode, index) => {
  clearTimeout(resetDomTimeout)
  resetDomTimeout = setTimeout(() => {
    headerNode.forEach((e, i) => {
      i !== index && (e.querySelector('.cell').style.paddingLeft = '12px')
    })
  }, 10)
}
const getTextWidth = (text) => {
  const dom = document.createElement('span')
  dom.style.display = 'inline-block'
  dom.textContent = text
  document.body.appendChild(dom)
  const width = dom.clientWidth
  document.body.removeChild(dom)
  return width
}
