import { ElMessage } from 'element-plus'

let removalSet = ''
const timeTip = (tip) => {
  removalSet = setTimeout(() => {
    ElMessage({
      message: tip,
      duration: 1 * 1000
    })
  }, 300)
}
const isInterface = (obj) => {
  clearTimeout(removalSet)
  const hash = window.location.hash
  // 页面刚开始不提示
  if (
    !obj.platform_id && !obj.first_category_ids.length && !obj.start_time
  ) {
    return
  }
  if (hash.endsWith('/shop/detail') && !obj.shop_name) {
    timeTip('请选择店铺')
    return false
  }
  if (hash.endsWith('/brand/detail') && !obj.brand_ids) {
    timeTip('请选择品牌')
    return false
  }
  if (!obj.platform_id) {
    timeTip('请选择平台')
    return false
  }
  if (
    !obj.first_category_ids.length &&
    !obj.second_category_ids.length &&
    !obj.third_category_ids.length
  ) {
    timeTip('请选择行业类目')
    return false
  }
  if (!obj.start_time || !obj.end_time) {
    timeTip('请选择日期')
    return false
  }
  return true
}

export default isInterface
