<template>
  <el-popover
    :width="320"
    @hide="clearSearch"
    placement="bottom"
    trigger="click"
    popper-class="brand-settings-container"
  >
    <template #reference>
      <el-button size="medium" icon="iconfont icon-shezhi"
        >{{ pageName }}关注设置</el-button
      >
    </template>
    <el-input
      v-model.trim="brandSearch"
      size="medium"
      :disabled="isSearch && !brandSearch"
      :placeholder="isSearch ? '关注已达上限' : `搜索添加关注${pageName}`"
    >
      <template #suffix>
        <i v-if="brandSearch" @click="clearSearch" class="iconfont icon-shanchu"></i>
        <i v-else class="iconfont icon-lurukuang_sousuo"></i>
      </template>
    </el-input>
    <div class="header-info">
      <span
        >还可以再关注
        <span class="brand-number">
          {{ isSearch ? 0 : focusOnTotal - checkboxData.length }}
        </span>
        个{{pageName}}
      </span>
      <span>{{ checkboxData.length }}/{{ focusOnTotal }}</span>
    </div>
    <div class="brand-scroll" v-loading="loading">
      <template v-if="!brandSearch && checkboxData.length">
        <div
          class="brand-content"
          :class="{'is-hover-brand': isRead}"
          v-for="d of checkboxData"
          :key="d.value"
          @click="isRead && noBrand(d.value)"
        >
          <span
            class="iconfont"
            v-if="isRead"
            :class="noBrandArr[d.value] ? 'icon-yincang': 'icon-xianshi'"
          ></span>
          <span class="brand-name">{{ d.label }}</span>
        </div>
      </template>
      <div
        v-else-if="(!brandSearch &&!checkboxData.length) || (brandSearch && !searchOption.length)"
        class="no-data"
      >
        <img src="@/assets/no-data/no-setting.png">
        <span>{{!brandSearch &&!checkboxData.length ? `暂无关注${pageName}`:'无相关数据'}}</span>
      </div>
      <template v-else>
        <div class="brand-content confirm-content" v-for="d of searchOption" :key="d.value">
          <span class="brand-name" v-html="getSearchContent(d.label)"></span>
          <el-popconfirm
            cancelButtonType="success"
            cancelButtonText="取消"
            confirmButtonText="确定"
            confirmButtonType="primary"
            icon="iconfont icon-tishi"
            iconColor="#B4B7BF"
            popper-class="brand-settings-popconfirm"
            :title="`温馨提示：关注${pageName}后不可取关，是否继续？`"
            @confirm="onAdd(d.value)"
            :disabled="isFocus(d)"
          >
            <template #reference>
              <el-button
                v-if="isFocus(d)"
                size="small"
                type="text"
                :disabled="isSearch"
              >已关注</el-button>
              <el-button
                v-else
                size="small"
                :disabled="isSearch"
              >关注</el-button>
            </template>
          </el-popconfirm>
        </div>
      </template>
    </div>
  </el-popover>
</template>
<script>
import { computed, defineComponent, nextTick, reactive, ref, watch } from 'vue'
import axios from '@/utils/request'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'BrandSettings',
  props: {
    brandType: String,
    websiteId: [String, Number],
    model: String,
    isRead: Boolean
  },
  emits: {
    onBrandSettingsEvent: null
  },
  setup (props, { emit }) {
    const name = { shop: '店铺', brand: '品牌' }
    const pageName = name[props.brandType]
    const brandSearch = ref('')

    const focusOnTotal = ref(0)
    const checkboxData = ref([])
    const noBrandArr = reactive({})
    const getList = () => {
      if (props.websiteId) {
        const url = {
          shop: '/api/v1/customer/subscribe_shop_list',
          brand: '/api/v1/customer/subscribe_brand_list'
        }
        const params = { website_id: props.websiteId }
        axios.get(url[props.brandType], { params }).then((res) => {
          if (res && res.error_code === 0) {
            checkboxData.value = res.data[`${props.brandType}_list`].map(
              (d) => {
                return { value: d.id || d.name, label: d.name, isChecked: true }
              }
            )
            emitFn()
            focusOnTotal.value = res.data.max_subscribe_count || 0
          }
        })
      } else {
        checkboxData.value = []
      }
    }

    const noBrand = (id) => {
      const list = checkboxData.value.filter(l => !noBrandArr[l.value]).map(l => l.value)
      if (props.model === 'self' && list.length === 1) {
        ElMessage.error(`请打开至少一个${pageName}的显示开关`)
        return
      }
      noBrandArr[id] = !noBrandArr[id]
      emitFn()
    }

    let isSettimeout = ''
    const loading = ref(false)
    const searchOption = ref([])
    watch(brandSearch, () => {
      if (!brandSearch.value) return
      if (!props.websiteId) {
        ElMessage.error('请选择平台')
        brandSearch.value = ''
        return
      }
      if (focusOnTotal.value - checkboxData.value.length <= 0) {
        ElMessage.error(`${pageName}关注已达上限，如需关注更多${pageName}，请联系商务`)
        brandSearch.value = ''
        return
      }
      clearTimeout(isSettimeout)
      isSettimeout = setTimeout(() => {
        if (brandSearch.value !== '') {
          loading.value = true
          const params = {
            website_id: props.websiteId,
            keyword: brandSearch.value
          }
          const searchUrl = {
            shop: '/api/v1/basedata/shop_list',
            brand: '/api/v1/basedata/brand_list'
          }
          const setValue = { shop: 'name', brand: 'id' }
          axios.get(searchUrl[props.brandType], { params }).then(res => {
            if (res && res.error_code === 0) {
              searchOption.value = res.data.map(d => {
                return { value: d[setValue[props.brandType]], label: d.name }
              })
            }
          }).finally(() => {
            loading.value = false
          })
        } else {
          searchOption.value = []
          loading.value = false
        }
      }, 300)
    })

    const getSearchContent = (label) => {
      return label.replace(brandSearch.value, `<span class="search-type">${brandSearch.value}</span>`)
    }

    const addUrl = {
      shop: '/api/v1/customer/subscribe_shop',
      brand: '/api/v1/customer/subscribe_brand'
    }
    const onAdd = (id) => {
      const key = { shop: 'shop_name', brand: 'brand_id' }
      const params = {
        website_id: props.websiteId,
        [key[props.brandType]]: id
      }
      axios.post(addUrl[props.brandType], params).then((res) => {
        if (res && res.error_code === 0) {
          ElMessage.success('添加成功')
          getList()
        }
      })
    }

    const emitFn = () => {
      const list = checkboxData.value.filter(l => !noBrandArr[l.value]).map(l => l.value)
      emit('onBrandSettingsEvent', list.join(','))
    }
    const clearSearch = () => {
      brandSearch.value = ''
    }

    // 页面初始化跟websiteId改变时执行
    if (props.websiteId) getList()
    watch(
      () => props.websiteId,
      () => {
        brandSearch.value = ''
        checkboxData.value = []
        focusOnTotal.value = 0
        getList()
      }
    )

    nextTick(() => {
      document.querySelector('.brand-scroll').onscroll = () => {
        document.querySelectorAll('.brand-settings-popconfirm').forEach(e => {
          e.style.display = 'none'
        })
      }
    })
    const isSearch = computed(() => focusOnTotal.value - checkboxData.value.length <= 0)
    const isFocus = computed(() => (d) => {
      const focusArr = checkboxData.value.map(l => l.value)
      return focusArr.includes(d.value)
    })
    return {
      isSearch,
      pageName,
      brandSearch,
      checkboxData,
      focusOnTotal,
      noBrand,
      noBrandArr,
      searchOption,
      loading,
      getSearchContent,
      onAdd,
      clearSearch,
      isFocus
    }
  }
})
</script>
<style lang="scss">
.brand-settings-container.el-popper {
  // z-index: 1000 !important;
  padding: 0 !important;
  z-index: 2067 !important;
  // margin-top: -11px !important;
  inset: -11px auto auto 0 !important;
  border: 0 !important;
  .el-popper__arrow {
    display: none;
  }
  .is-disabled input {
    background-color: #f7f8fa !important;
  }
  .icon-yincang {
    color: #84868c;
    &+.brand-name {
      color: #84868c;
    }
  }
  .el-input {
    width: calc(100% - 24px);
    margin: 12px 12px 0;
    .el-input__suffix {
      right: 12px;
    }
    .el-input__suffix i {
      color: #b4b7bf;
      font-size: 14px;
    }
  }
  .header-info {
    height: 44px;
    margin: 0 12px 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e9ecf2;
    span {
      color: #84868c;
    }
    .brand-number {
      color: #121200;
    }
  }
  .brand-scroll {
    height: 288px;
    overflow-y: auto;
    margin-bottom: 9px;
    box-sizing: border-box;
    .search-type {
      color: #F04848;
    }
    .el-loading-parent--relative {
      overflow: hidden !important;
    }
    .no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction:column;
      height: 100%;
      img {
        width: 120px;
        height: 100px;
      }
      span {
        margin-top: 12px;
        text-align: center;
        color: #B4B7BF;
      }
    }
  }
  .is-hover-brand {
    cursor: pointer;
    &:hover {
      background: rgb(255, 246, 246);
    }
  }
  .brand-content {
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    .el-button--text span {
      font-size: 14px;
      font-weight: 400;
      color: #F04848;
    }
    .el-button--default {
      height: 30px !important;
      span {
        font-size: 14px;
        font-weight: 400;
      }
    }
    .iconfont {
      margin-right: 8px;
    }
    .brand-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .confirm-content {
    justify-content: space-between;
    padding: 0 12px;
    .brand-name {
      flex: 1;
    }
    .el-button--small {
      width: 48px;
      height: 26px;
      min-height: 26px;
      padding: 0;
    }
  }
}
.brand-settings-popconfirm.el-popper {
  z-index: 9999 !important;
}
.brand-settings-popconfirm {
  padding: 10px 12px !important;
  border: 0 !important;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .el-popconfirm__main {
    color: #333333;
    margin-bottom: 16px;
  }
  .icon-tishi {
    font-size: 14px;
  }
  .el-popconfirm__action {
    .el-button--success {
      background-color: #fff;
      border-color: #D0D3D9;
      border-radius: 2px;
      span, i {
        color: #84868C;
      }
      &:hover, &:focus {
        border-color: #F04848 !important;
        background-color: #fff;
        span, i {
          color: #F04848 !important;
        }
      }
    }
  }
}
.navigation-top-setup {
  .el-button--default {
    display: flex;
    align-items: center;
    .iconfont {
      color: #F04848;
      margin-right: 5px;
    }
  }
  button, .el-radio span {
    font-weight: 400;
  }

}
</style>
