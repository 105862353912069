<template>
  <div class="menu-container" v-show="isShowMenu">
    <div class="menu-header" v-show="store.state.menuType === 1">
      <div class="menu-header-content">
        <span class="iconfont icon-dianshangdashuju"></span>
        <h1>电商大数据</h1>
      </div>
    </div>
    <el-menu
      :default-active="defaultActive"
      v-show="store.state.menuType === 1"
      class="el-menu-vertical-demo"
      router
      @select="selectMenu"
      text-color="#242426"
      active-text-color="#f04848"
      unique-opened
      :collapse-transition="false"
      :default-openeds="['5-1']">
        <el-sub-menu v-show="!itemRoute.hidden" class="submenu" :index="itemRoute.meta.index" v-for="(itemRoute, index) in routes" :key="index">
          <template #title>
            <div class="menu-list">
              <i
                class="iconfont"
                :class="[
                  itemRoute.meta.index === activeSubmenu ? 'active-submenu' : '',
                  itemRoute.meta.icon
                ]"
              ></i>
              <span :class="
              {
                'active-submenu': itemRoute.meta.index === activeSubmenu,
              }">{{ itemRoute.meta.title }}</span>
            </div>
          </template>
          <el-menu-item v-show="!childItem.hidden" :index="childItem.path" :class="`menu-item ${childItem.path === activeMenuItem ? 'active-menu-item' : ''}`" v-for="(childItem, childIndex) in itemRoute.children" :key="childIndex">
            {{ childItem.meta.title }}
          </el-menu-item>
        </el-sub-menu>
    </el-menu>
    <el-menu
      :default-active="defaultActive"
      v-show="store.state.menuType === 2"
      class="el-menu-vertical-demo"
      router
      @select="selectMenu"
      text-color="#242426"
      active-text-color="#f04848"
      :collapse-transition="false"
      :default-openeds="['9-1']">
      <el-sub-menu class="submenu" index="9-1">
        <template #title>
          <div class="menu-list">
            <i class="iconfont icon-celan_yonghuzhongxin active-submenu"></i>
            <span class="active-submenu">用户中心</span>
          </div>
        </template>
        <el-menu-item index="/person" :class="menuClass('/person')">用户信息</el-menu-item>
        <el-menu-item index="/settings" :class="menuClass('/settings')" v-if="isChildAccount">子账号信息</el-menu-item>
      </el-sub-menu>
    </el-menu>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { filterRouters } from '@/utils/index'

export default defineComponent({
  name: 'NavigationMenu',
  setup () {
    const store = reactive(useStore())

    const router = reactive(useRouter())
    const routes = reactive(router.options.routes)

    // 过滤无权限路由
    filterRouters(store.state.permission, routes)

    const activeSubmenu = ref('')
    const activeMenuItem = ref('')
    const selectMenu = (path, current) => {
      activeSubmenu.value = current[0]
      activeMenuItem.value = path
    }

    // 点击一次菜单后默认展开并进入首个子菜单
    // const openMenu = (key, keyPath) => {
    //   const findKey = routes.find(i => i.meta.index === key)
    //   if (findKey && findKey.children && findKey.children.length && findKey.children[0].path) {
    //     router.push(findKey.children[0].path)
    //   }
    // }

    const defaultActive = ref('')
    // 初始化默认选中状态
    const path = window.location.href.split('#')[1].split('?')[0]
    // 默认值
    defaultActive.value = path
    // 查询此路径匹配的index
    const findRoute = routes.find(i => {
      if (i.path !== '/') {
        return path.includes(i.path)
      }
    })
    // 模拟选中
    if (findRoute) {
      selectMenu(path, [findRoute.meta.index])
    }

    router.beforeEach((to, from, next) => {
      // 默认值
      defaultActive.value = to.path
      // 查询此路径匹配的index
      const findRoute = routes.find(i => {
        if (i.path !== '/') {
          return to.path.includes(i.path)
        }
      })
      // 模拟选中
      if (findRoute) {
        selectMenu(to.path, [findRoute.meta.index])
      }
      next()
    })

    const menuClass = computed(() => (name) => `menu-item ${activeMenuItem.value === name ? 'active-menu-item' : ''}`)
    const isChildAccount = computed(() => !store.state.parentId && store.state.insider)
    const isShowMenu = computed(() => !['/login'].includes(store.state.nowUrl))

    return {
      routes,
      store,
      selectMenu,
      isShowMenu,
      activeSubmenu,
      activeMenuItem,
      defaultActive,
      menuClass,
      isChildAccount
      // openMenu
    }
  }
})
</script>

<style lang="scss" scoped>
.menu-container {
  width: 186px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  flex: 0 0 186px;
  position: fixed;
  left: calc(50% - 600px);
  z-index: 1000;
  padding-bottom: 6px;
  background: #fff;
}

.menu-header {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: #fff;

  .menu-header-content {
    display: flex;
    width: 138px;
    margin: 0 auto;
    border-bottom: 1px solid #E9E9F2;
    line-height: 50px;

    span {
      font-size: 20px;
      color: #F04848;
    }

    h1 {
      font-size: 16px;
      color: #242426;
      margin-left: 14px;
    }
  }
}

</style>
<style lang="scss">
.el-menu {
  border-right: none !important;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.submenu {
    font-family: PingFangSC-Regular;

    &.is-active .el-icon-arrow-down {
      color: #f04848 !important;
    }
    .el-sub-menu__title {
      display: flex;
      align-items: center;
      padding: 0 !important;
      height: 48px;

      &:hover {
        background-color: #fff;
      }

      .menu-list {
        padding-left: 25px !important;
        padding-right: 31px !important;

        .iconfont {
          color: #B4B7BF;
          font-size: 16px;
        }

        span {
          margin-left: 12px;
          line-height: 20px;
          font-size: 14px;
        }

        .active-submenu {
          color: #f04848 !important;
        }

      }

      .el-sub-menu__icon-arrow {
        right: 26px;
        margin-top: -5px;
      }

    }

    .el-menu-item {
      height: 48px;
      line-height: 48px;
      padding: 0 0 0 57px !important;
      min-width: auto !important;
      position: relative;
      border-left: 2px solid transparent;
      text-align: left;

      &:hover {
        background-color: rgba(240, 72, 72, .04);
        border-color: #f04848;
      }
    }

    .active-menu-item {
      background-color: rgba(240, 72, 72, .04) !important;
      border-color: #f04848 !important;
    }
  }
</style>
