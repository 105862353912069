// 判断当前放大拖拽是否为左侧
let isPrevDataZoom = true
export default function dataZoomLabel (value) {
  if (value.split('~')[1]) {
    if (isPrevDataZoom) {
      isPrevDataZoom = !isPrevDataZoom
      return value.split('~')[0]
    }
    isPrevDataZoom = !isPrevDataZoom
    return `${value.split('-')[0]}-${value.split('~')[1].trim()}`
  }
  return value
}
