import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store'
import IndustryRank from '@/views/Industry/Rank.vue'
import Layout from '@/views/Layout.vue'

const routes = [
  {
    path: '/',
    hidden: true,
    meta: { title: '', index: '1-1', key: '' },
    component: IndustryRank,
    children: []
  },
  {
    path: '/login',
    hidden: true,
    meta: { title: '登录页', index: '2-1', key: '' },
    component: () => import('@/views/Login'),
    children: []
  },
  {
    path: '/404',
    hidden: true,
    meta: { title: '404', index: '404-1', key: '' },
    component: () => import('@/views/404'),
    children: []
  },
  {
    path: '/industry',
    hidden: true,
    meta: { title: '行业分析', index: '5-1', key: 'industry', icon: 'icon-celan_hangyefenxi' },
    component: Layout,
    children: [
      {
        path: '/industry/rank',
        hidden: true,
        meta: { title: '行业排行', index: '5-1-1', key: 'industry.ranking' },
        component: () => import('@/views/Industry/Rank')
      }
    ]
  },
  {
    path: '/brand',
    hidden: true,
    meta: { title: '品牌分析', index: '6-1', key: 'brand', icon: 'icon-celan_pinpaifenxi' },
    component: Layout,
    children: [
      {
        path: '/brand/rank',
        hidden: true,
        meta: { title: '品牌排行', index: '6-1-1', key: 'brand.ranking' },
        component: () => import('@/views/Brand/Rank')
      },
      {
        path: '/brand/detail',
        hidden: true,
        meta: { title: '品牌详情', index: '6-1-2', key: 'brand.details' },
        component: () => import('@/views/Brand/Detail')
      }
    ]
  },
  {
    path: '/shop',
    hidden: true,
    meta: { title: '店铺分析', index: '7-1', key: 'shop', icon: 'icon-celan_dianpufenxi' },
    component: Layout,
    children: [
      {
        path: '/shop/rank',
        hidden: true,
        meta: { title: '店铺排行', index: '7-1-1', key: 'shop.ranking' },
        component: () => import('@/views/Shop/Rank')
      },
      {
        path: '/shop/detail',
        hidden: true,
        meta: { title: '店铺详情', index: '7-1-2', key: 'shop.details' },
        component: () => import('@/views/Shop/Detail')
      }
    ]
  },
  {
    path: '/goods',
    hidden: true,
    meta: { title: '商品分析', index: '8-1', key: 'product', icon: 'icon-celan_shangpinfenxi' },
    component: Layout,
    children: [
      {
        path: '/goods/rank',
        hidden: true,
        meta: { title: '商品排行', index: '8-1-1', key: 'product.ranking' },
        component: () => import('@/views/Goods/Rank')
      },
      {
        path: '/goods/detail',
        hidden: true,
        meta: { title: '商品详情', index: '8-1-2', key: 'product.details' },
        component: () => import('@/views/Goods/Detail')
      }
    ]
  },
  {
    path: '/person',
    hidden: true,
    meta: { title: '用户信息', index: '9-1', key: '' },
    component: () => import('@/views/Person/index'),
    children: []
  },
  {
    path: '/settings',
    hidden: true,
    meta: { title: '子账号信息', index: '10-1', key: '' },
    component: () => import('@/views/Settings/index'),
    children: []
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

// 路由前置守卫
router.beforeEach((to, from, next) => {
  store.commit('changeNowUrl', to.fullPath)
  const token = setInfo()
  if (to.path === '/login') {
    // 如果退出登录 直接跳转至登录页
    next()
  } else if (!token) {
    store.commit('changeMenuType', 0)
    next('/login')
  } else if (token) {
    store.commit('changeLoginState', true)
    if (to.path === '/' && from.path === '/') {
      next('/industry/rank')
    } else {
      // 改变菜单类型
      const changeMenuType = to.path === '/person' || to.path === '/Person' || to.path === '/settings' || to.path === '/Settings' ? 2 : 1
      store.commit('changeMenuType', changeMenuType)

      if (to.meta.key) {
        if (store.state.permission.includes(to.meta.key)) {
          next()
        } else {
          next('/404')
        }
      } else {
        next()
      }
    }
  }
})

const setInfo = () => {
  const getUser = window.localStorage.getItem('DATAZHI-USER')
  const token = window.localStorage.getItem('DATAZHI-TOKEN')
  store.commit('setToken', token)

  if (getUser && token) {
    const user = JSON.parse(getUser)
    store.commit('setPhone', user.phone || '')
    store.commit('setUsername', user.name || '')
    store.commit('setParentId', user.parent_id || '')
    store.commit('setValidityTime', user.validity_time || '')
    store.commit('setPermission', user.menu || '')
    store.commit('setInsider', !user.insider)
  }
  return token
}

export default router
