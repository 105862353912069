import { toThousands } from '@/utils/index'

// 一级列表头数据源
const firstTableDataObj = {
  category_name: '行业类目',
  pic_url: '商品信息'
}
// 二级三级列表头数据源
const secondTableDataObj = {
  amount: '销售金额',
  num: '销售件数',
  avg: '成交价',
  total: '总金额',
  youhui: '营销透视',
  percentage: '占比',
  continuous_ratio: '环比',
  last_ratio: '同比',
  info: '促销活动'
}

function createColumns (tableData) {
  const firstObj = tableData.rows[0] || {}
  const columns = []
  const firstFormatColumn = {}
  const secondFormatColumn = {}

  firstTableDataObj.category_name = '行业类目'
  if (window.location.hash.endsWith('/brand/rank')) {
    firstTableDataObj.category_name = '品牌'
  } else if (window.location.hash.endsWith('/shop/rank')) {
    firstTableDataObj.category_name = '店铺'
  }
  if (firstObj.pic_url || firstObj.today_price || firstObj.today_price === 0) {
    columns.push({ prop: 'pic_url', label: firstTableDataObj.pic_url })
  } else if (firstObj.category_name) {
    columns.push({ prop: 'category_name', label: firstTableDataObj.category_name })
  }
  // 格式化接口获取数据
  Object.keys(firstObj).forEach(prop => {
    if (!firstTableDataObj[prop]) {
      const propArr = prop.split('_', 3)
      const s1Key = `${propArr[0]}-${propArr[1]}`
      // 创建一级二级对应关系
      if (!firstFormatColumn[propArr[0]] && secondTableDataObj[propArr[1]]) {
        firstFormatColumn[propArr[0]] = {}
      }
      if (secondTableDataObj[propArr[1]]) {
        firstFormatColumn[propArr[0]][s1Key] = secondTableDataObj[propArr[1]]
      }

      // 创建二级三级对应关系
      if (!secondFormatColumn[s1Key]) {
        secondFormatColumn[s1Key] = []
      }
      let label = secondTableDataObj[propArr[2]] || secondTableDataObj[`${propArr[2]}_ratio`]
      if (propArr[1] === 'num' && propArr[2] === 'total') {
        label = '总件数'
      }
      if (propArr[1] === 'avg' && propArr[2] === 'total') {
        label = '均价（元）'
      }
      label && secondFormatColumn[s1Key].push({ prop, label, 'min-width': '120px' })
    }
  })

  // 重新构建column数据
  const columnArr = Object.keys(firstFormatColumn).map(fi => {
    const children = Object.keys(firstFormatColumn[fi]).map(prop => {
      return { prop, label: firstFormatColumn[fi][prop], children: secondFormatColumn[prop] }
    })
    return { prop: fi, label: tableData.cols[fi], children }
  })
  return [...columns, ...columnArr]
}

// 过滤表格数据
function filterColumns (columns, filterArr) {
  // 保留原始数据
  let data = JSON.parse(JSON.stringify(columns))

  data.forEach(c => {
    const isSave = filterArr.some(f => c.prop.endsWith(f))
    if (isSave) {
      c.hide = true
    } else if (c.children) {
      c.children = filterColumns(c.children, filterArr)
    }
  })

  // 数据过滤
  data = data.filter(c => !c.hide)
  return data
}

// 初始化数字表格增加减少标识
const signArr = ['continuous_ratio', 'last_ratio']
const formatClass = (prop, num) => {
  const isSign = signArr.some(s => prop.endsWith(s))
  if (!isSign) return

  const isAdd = Number(num) > 0
  return isAdd ? 'red' : 'green'
}

// 数字千位符格式化
const formatArr = ['total']
const formatNum = (prop, num) => {
  const isFormat = formatArr.some(s => prop.endsWith(s))
  if (!isFormat) return `${Number(num) ? num.toFixed(2) : 0}%`
  return toThousands(Number(num) ? num.toFixed(2) : 0)
}

export { createColumns, filterColumns, formatClass, formatNum }
