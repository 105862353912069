import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '@/store'
import router from '@/router'
import loading from '@/components/loading.js'
import { setParams } from './saveLog'
const service = axios.create({
  baseURL: '/',
  withCredentials: true,
  timeout: 60000
})

service.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
let loadingUrl = {}

service.interceptors.request.use(
  config => {
    if (config.loading) {
      // 避免单个页面调用同样的url，loading重置错误问题，如果url重复，需要多传一个唯一的type
      loadingUrl[`${config.url}${config?.params?.type || ''}`] = true
      loading.create()
    }
    if (store.state.token) {
      config.headers.Authorization = `zdm ${store.state.token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    const { url, params } = response.config
    const loadingKey = `${url}${params?.type || ''}`
    if (loadingUrl[loadingKey]) {
      delete loadingUrl[loadingKey]
      !Object.keys(loadingUrl).length && loading.close()
    }

    // if (res.error_code === 5000) {
    //   location.replace('https://datazhi-bgm.smzdm.com/redirect/index')
    //   return res
    // }
    // 埋点接口报错不需提示
    if (url === '/api/v1/operate_log/save_log') {
      return
    }

    if (res.error_code !== 0 && res.error_code !== 10001) {
      ElMessage({
        message: res.error_msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })

      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      setParams(url, params, res.data)

      return res
    }
  },
  error => {
    loadingUrl = {}
    loading.close()

    // 处理401无权限 status code 401时无法获取error_code 在reject里截获处理
    const errStr = error + ''
    if (errStr.includes('status code 401')) {
      store.commit('changeLoginState', false)
      router.push('/login')
      return
    }

    if (error.code !== 'ECONNABORTED') {
      ElMessage({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service
