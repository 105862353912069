import { useStore } from 'vuex'

const store = useStore()
const phone = store?.state?.phone || ''
export default function key () {
  const isBrand = () => {
    return window.location.href.includes('brand/detail')
  }
  const isShop = () => {
    return window.location.href.includes('shop/detail')
  }
  return `${phone}${isBrand() ? '_brand' : isShop() ? '_shop' : ''}`
}
