const industryAmountTooltip = `
  1.销售金额：在一定时期内产品销售的货币收入 </br>
  2.行业范围：您所选的全部行业类目</br>
  3.时间范围：您所指定时间区间覆盖的自然日、自然周、自然月、自然年。（举例：您所选的日期6月1-7日。假如：1日所在第一周的周二，7日所在第二周的周一。如果选择以日查看则是7个自然日。但以周查看的话，则是覆盖两个自然周的数据，第一周：5月31日至6月6日，第二周：6月7日至6月13日。自然月、自然年以此类推。）</br>
  4.销售：以实际支付为统计节点。后续如发生全额退款或部分退款，则以退款发生时间重新界定。
`

const industryNumTooltip = `
  1.销售件数：在一定时期内产品销售的件数</br>
  2.行业范围：您所选的全部行业类目</br>
  3.时间范围：您所指定时间区间覆盖的自然日、自然周、自然月、自然年。（举例：您所选的日期6月1-7日。假如：1日所在第一周的周二，7日所在第二周的周一。如果选择以日查看则是7个自然日。但以周查看的话，则是覆盖两个自然周的数据，第一周：5月31日至6月6日，第二周：6月7日至6月13日。自然月、自然年以此类推。）</br>
  4.销售：以实际支付为统计节点。后续如发生全额退款或部分退款，则以退款发生时间重新界定。
`

const brandAmountTooltip = `
  1.销售金额：在一定时期内产品销售的货币收入</br>
  2.数据范围：您所选的行业类目下指定品牌的全部销售金额。</br>
  3.时间范围：您所指定时间区间覆盖的自然日、自然周、自然月、自然年。（举例：您所选的日期6月1-7日。假如：1日所在第一周的周二，7日所在第二周的周一。如果选择以日查看则是7个自然日。但以周查看的话，则是覆盖两个自然周的数据，第一周：5月31日至6月6日，第二周：6月7日至6月13日。自然月、自然年以此类推。）</br>
  4.销售：以实际支付为统计节点。后续如发生全额退款或部分退款，则以退款发生时间重新界定。
`

const brandNumTooltip = `
  1.销售件数：在一定时期内产品销售的件数</br>
  2.数据范围：您所选的行业类目下指定品牌的全部销售件数。</br>
  3.时间范围：您所指定时间区间覆盖的自然日、自然周、自然月、自然年。（举例：您所选的日期6月1-7日。假如：1日所在第一周的周二，7日所在第二周的周一。如果选择以日查看则是7个自然日。但以周查看的话，则是覆盖两个自然周的数据，第一周：5月31日至6月6日，第二周：6月7日至6月13日。自然月、自然年以此类推。）</br>
  4.销售：以实际支付为统计节点。后续如发生全额退款或部分退款，则以退款发生时间重新界定。
`

const goodsAmountTooltip = `
  1.销售金额：在一定时期内产品销售的货币收入</br>
  2.数据范围：您所选的行业类目下指定商品的全部销售金额。</br>
  3.时间范围：您所指定时间区间覆盖的自然日、自然周、自然月、自然年。（举例：您所选的日期6月1-7日。假如：1日所在第一周的周二，7日所在第二周的周一。如果选择以日查看则是7个自然日。但以周查看的话，则是覆盖两个自然周的数据，第一周：5月31日至6月6日，第二周：6月7日至6月13日。自然月、自然年以此类推。）</br>
  4.销售：以实际支付为统计节点。后续如发生全额退款或部分退款，则以退款发生时间重新界定。
`
const goodsNumTooltip = `
  1.销售件数：在一定时期内产品销售的件数</br>
  2.数据范围：您所选的行业类目下指定店铺的全部销售件数。</br>
  3.时间范围：您所指定时间区间覆盖的自然日、自然周、自然月、自然年。（举例：您所选的日期6月1-7日。假如：1日所在第一周的周二，7日所在第二周的周一。如果选择以日查看则是7个自然日。但以周查看的话，则是覆盖两个自然周的数据，第一周：5月31日至6月6日，第二周：6月7日至6月13日。自然月、自然年以此类推。）</br>
  4.销售：以实际支付为统计节点。后续如发生全额退款或部分退款，则以退款发生时间重新界定。
`
const shopAmountTooltip = `
  1.销售金额：在一定时期内产品销售的货币收入</br>
  2.品牌范围：您所选的全部店铺</br>
  3.时间范围：您所指定时间区间覆盖的自然日、自然周、自然月、自然年。（举例：您所选的日期6月1-7日。假如：1日所在第一周的周二，7日所在第二周的周一。如果选择以日查看则是7个自然日。但以周查看的话，则是覆盖两个自然周的数据，第一周：5月31日至6月6日，第二周：6月7日至6月13日。自然月、自然年以此类推。）</br>
  4.销售：以实际支付为统计节点。后续如发生全额退款或部分退款，则以退款发生时间重新界定。
`
const shopNumTooltip = `
  1.销售件数：在一定时期内产品销售的件数</br>
  2.品牌范围：您所选的全部店铺</br>
  3.时间范围：您所指定时间区间覆盖的自然日、自然周、自然月、自然年。（举例：您所选的日期6月1-7日。假如：1日所在第一周的周二，7日所在第二周的周一。如果选择以日查看则是7个自然日。但以周查看的话，则是覆盖两个自然周的数据，第一周：5月31日至6月6日，第二周：6月7日至6月13日。自然月、自然年以此类推。）</br>
  4.销售：以实际支付为统计节点。后续如发生全额退款或部分退款，则以退款发生时间重新界定。
`
export {
  industryAmountTooltip,
  industryNumTooltip,
  brandAmountTooltip,
  brandNumTooltip,
  goodsAmountTooltip,
  goodsNumTooltip,
  shopAmountTooltip,
  shopNumTooltip
}
