<template>
  <div class="chart-table">
    <div class="table-header">
      <el-checkbox-group v-model="firstArr">
        <el-checkbox
          v-for="{ prop, label, tooltip } of firstColumns"
          :label="prop"
          :key="prop"
          >{{ label }}
          <el-tooltip
            popper-class="table-header-tooltip"
            effect="dark"
            :content="tooltip || ''"
            placement="top"
          >
            <template #content>
              <header>{{ label }}:</header>
              {{ tooltip }}
            </template>
            <span class="iconfont icon-bangzhu" v-show="tooltip"></span>
          </el-tooltip>
        </el-checkbox>
      </el-checkbox-group>
      <el-checkbox-group v-model="secondArr">
        <el-checkbox
          v-for="{ prop, label } of secondColumns"
          :label="prop"
          :key="prop"
        >
          {{ label }}
          <el-tooltip
            popper-class="search-tooltip"
            effect="dark"
            :placement="tooltipPlacement"
            v-if="prop.endsWith('continuous_ratio')"
          >
            <template #content>
              <header>环比:</header>
              与上一统计段比较，例如2000年7月与2000年6月数据相比，称其为环比。
              <br />
              公式：环比=(本期数－上期数)÷上期数×100%<br />
              例子：比如2000年1月份的销售金额是100万，2000年2月份的销售金额是150万，环比增速=(150-100)÷100×100%=50%
            </template>
            <span class="iconfont icon-bangzhu"></span>
          </el-tooltip>
          <el-tooltip
            popper-class="search-tooltip"
            effect="dark"
            content=""
            :placement="tooltipPlacement"
            v-if="prop.endsWith('last_ratio')"
          >
            <template #content>
              <header>同比:</header>
              与历史同时期比较，例如2001年7月与2000年7月数据相比，称其为同比。<br />
              公式：同比=(本期数－同期数)÷同期数×100%<br />
              例子：比如去年3月的销售金额是100万，今年3月的销售金额是300万，同比增长率=(300－100)÷100×100%=200%
            </template>
            <span class="iconfont icon-bangzhu"></span>
          </el-tooltip>
        </el-checkbox>
      </el-checkbox-group>
      <slot name="search"></slot>
      <el-tooltip
        effect="dark"
        :content="
          tableData.download_pms && !tableData.download_pms.permission
            ? tableData.download_pms.message
            : '下载'
        "
        placement="top"
        popper-class="table-search-tooltip table-header-tooltip"
      >
        <el-button
          icon="iconfont icon-xiazai"
          size="small"
          :class="{ disabledBtn: isDisabledBtn }"
          @click="
            download(tableId, downloadNameComputed, !isDisabledBtn)
          "
        ></el-button>
      </el-tooltip>
    </div>
    <noData v-show="noData"></noData>
    <slot name="screen" v-if="!noData"></slot>
    <el-table
      v-show="!noData"
      :data="tableRow || []"
      :max-height="tableMaxHeight"
      :id="tableId"
      :header-cell-style="{padding: '7px 0'}"
      :span-method="arraySpanMethod"
      class="downloadTable"
      ref="downloadTable"
      @mousedown="eventDown"
      @mouseup="eventUp"
      @mousemove="eventMove"
      @sort-change="sortableMethod"
    >
      <el-table-column fixed type="index" label="排名" width="60">
      </el-table-column>
      <el-table-column
        v-if="isShop"
        width="1"
        prop="pro_link"
        label="链接"
        class-name="pro-link"
      >
        <template #default="{ row }">
          <div>https://{{ row.pro_link }}</div>
        </template>
      </el-table-column>
      <el-table-column
        v-for="c of columnsTable.columns"
        :key="c.prop"
        :prop="c.prop"
        :label="c.label"
        :fixed="isFixed(c.prop)"
        header-align="left"
        :min-width="
          c.prop === 'pic_url'
            ? '321'
            : c.prop === 'category_name'
            ? '188'
            : '100'
        "
      >
        <template #default="{ row }">
          <template v-if="c.children && c.children.length">
            <el-table-column
              :prop="prop || ''"
              :label="label"
              v-for="{ prop, label, children } of c.children"
              :key="prop"
              header-align="center"
            >
              <template v-if="children && children.length">
                <el-table-column
                  v-for="{ prop, label } of children"
                  :key="prop"
                  :prop="prop || ''"
                  :label="label"
                  :sortable="!prop.endsWith('youhui_info') && 'custom'"
                  :sort-orders="['descending', 'ascending', null]"
                  :min-width="
                    prop.endsWith('amount_total') || prop.endsWith('num_total')
                        ? 158 : 120
                  "
                  :align="prop.endsWith('info') ? 'left' : 'right'"
                  :class-name="prop.endsWith('percentage') ? 'pro-link' : ''"
                  :width="prop.endsWith('percentage') ? '1px' : 'auto'"
                >
                  <template #default="{ row }">
                    <div v-if="prop.endsWith('amount_total') || prop.endsWith('num_total')" class="amount-total">
                      <div>{{tableText(prop, row)}}</div>
                      <el-progress
                        v-if="isPercentage"
                        :percentage="amuntPercentage(prop, row)"
                        :color="prop.endsWith('amount_total') ? '#F04848' : '#435B8E'"
                        :stroke-width="4"
                      ></el-progress>
                    </div>
                    <span
                      v-else-if="prop.endsWith('youhui_info') && isDetailRoute"
                      class="preferential"
                      @click="picRoute(row.rank)"
                      >{{ row[prop] }}</span
                    >
                    <span v-else-if="prop.endsWith('youhui_info')">{{
                      row[prop]
                    }}</span>
                    <span v-else :class="formatClass(prop, row[prop])">
                      {{ tableText(prop, row) }}
                    </span>
                  </template>
                </el-table-column>
              </template>
              <template #header v-if="prop.endsWith('avg')"> 成交价 </template>
            </el-table-column>
          </template>
          <!-- 商品排行商品信息 -->
          <template v-else-if="['today_price', 'pic_url'].includes(c.prop)">
            <div class="goods-detail">
              <img :src="row.pic_url" v-if="row.pic_url" />
              <img
                src="@/assets/no-data/goods-rank.png"
                v-else-if="isGoodsRank"
              />
              <div>
                <div
                  v-if="isDetailRoute"
                  @click="picRoute(row.rank)"
                  class="picRoute"
                >
                  {{ row.category_name || "" }}
                </div>
                <div v-else>{{ row.category_name || "" }}</div>
                <div class="goods-name" v-if="isPercentage">
                  <span v-if="row.shop_name">{{ row.shop_name }}</span>
                  <span>
                    当前价格：<span class="goods-price"
                      >¥ {{ row.today_price || "0" }}</span
                    >
                  </span>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              class="preferential"
              @click="goShopDetail(row)"
              v-if="row.actived"
            >
              {{ row[c.prop] }}
            </div>
            <el-tooltip
              v-else
              effect="dark"
              :disabled="!typeName"
              :content="`请先在${typeName}关注设置中添加您关注的${typeName}，即可查看${typeName}详情。`"
              placement="top"
              ><div>{{ row[c.prop] }}</div></el-tooltip
            >
          </template>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, watch, nextTick, computed } from 'vue'
// import { ElMessage } from 'element-plus'
import { tableToExcel, getTime, toThousands } from '@/utils/index'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import noData from '@/components/no-data'
import watermark from '@/components/directives'
import tableFirstHeaderScroll from '@/components/table-first-header-scroll'
import {
  createColumns,
  filterColumns,
  formatClass,
  formatNum
} from './format-column'
import leftScroll from './table-left-scroll'
import { downloadTableLog } from '@/utils/saveLog'
const FIRST_COLUMNS = [
  {
    prop: 'amount',
    label: '销售金额'
  },
  {
    label: '销售件数',
    prop: 'num'
  }
]
const SECOND_COLUMNS = [
  {
    prop: 'percentage',
    label: '占比'
  },
  {
    prop: 'continuous_ratio',
    label: '环比'
  },
  {
    prop: 'last_ratio',
    label: '同比'
  }
]

export default defineComponent({
  components: { noData },
  props: {
    tableData: {
      type: Object,
      default: () => {}
    },
    typeName: {
      type: String,
      default: ''
    },
    tooltipPlacement: {
      type: String,
      default: 'top'
    },
    tableMaxHeight: {
      type: String,
      default: '635'
    },
    firstColumns: {
      type: Array,
      default: () => FIRST_COLUMNS
    },
    secondColumns: {
      type: Array,
      default: () => SECOND_COLUMNS
    },
    resetTimes: {
      type: Object,
      default: () => {}
    },
    downloadName: {
      type: String,
      default: '类目表'
    },
    index: {
      type: String,
      default: ''
    }
  },
  emits: {
    goShop: null
  },
  setup (props, { emit }) {
    const router = useRouter()
    const columnsTable = reactive({ columns: [] })
    // 保留原始数据
    let defaultColumns = []
    // 创建初始化数据
    const firstDefault = props.firstColumns.map((d) => d.prop)
    const secondDefault = props.secondColumns.map((d) => d.prop)

    const firstArr = ref(firstDefault)
    const secondArr = ref(secondDefault)

    const downloadTable = ref(null)
    const noData = ref(true)

    // 商品图片url重置
    const tableRow = ref([])
    watch(props.tableData, () => {
      const { cols, rows } = props.tableData
      tableRow.value = JSON.parse(JSON.stringify(props.tableData.rows))
      if (!(cols && cols.length) && !(rows && rows.length)) {
        noData.value = true
      } else {
        noData.value = false
        defaultColumns = createColumns(props.tableData)
        resetTableHead()

        nextTick(() => {
          downloadTable.value.doLayout()
        })
        headerFloatLeft()
      }
    })

    watch(firstArr, (newvalue) => {
      noData.value = false
      if (newvalue.length === 0) {
        // firstArr.value = oldValue
        // ElMessage({ message: '至少选择1个选项', type: 'error' })
        noData.value = true
        return
      }
      resetTableHead()
    })
    const isPercentage = ref(true)
    watch(secondArr, () => {
      isPercentage.value = secondArr.value.includes('percentage')
      resetTableHead()
    })
    const resetTableHead = (isLazy = true) => {
      const excludeFirst = firstDefault.filter(
        (f) => !firstArr.value.includes(f)
      )
      const excludeSecond = secondDefault.filter(
        (f) => !secondArr.value.includes(f)
      )
      const column = filterColumns(defaultColumns, [
        ...excludeFirst,
        ...excludeSecond
      ])

      if (isLazy) {
        leftScroll(tableId.value, column, columnsTable, headerFloatLeft)
      } else {
        columnsTable.columns = column
      }
      headerFloatLeft()
    }

    // 表格一级头部如果有二级三级需要让一级在最左侧滚动
    const headerFloatLeft = () => {
      setTimeout(() => {
        watermark({
          watermarl_element: 'downloadTable',
          tag: 'class'
        })
        tableFirstHeaderScroll()
      }, 2000)
    }
    const summaryMethod = () => {
      return `销售总金额: ${toThousands(props.tableData.allGmv)}元, 销售总件数: ${toThousands(props.tableData.allSales)}件`
    }
    const fixedArr = ['category_name', 'pic_url']
    const isFixed = (type) => fixedArr.includes(type)

    const picRoute = (rank) => {
      const url = props.tableData.rows.find((t) => t.rank === rank).pro_link
      const { start_time: start, end_time: end } = props.resetTimes.dateValue
      const { href } = router.resolve({
        path: '/goods/detail',
        query: { url, startTime: start, endTime: end }
      })
      window.open(href, '_blank')
    }

    const isDisabledBtn = computed(
      () =>
        !(
          props.tableData.download_pms &&
          props.tableData.download_pms.permission
        )
    )

    const tableText = (prop, row) => {
      return `${
        row[prop] || row[prop] === 0
          ? (formatClass(prop, row[prop]) === 'red' ? '+' : '') +
            formatNum(prop, row[prop])
          : '-'
      }`
    }

    // 表格拖拽滚动
    const scrollLock = ref(false)
    const scrollPosition = ref(0)
    const eventDown = (e) => {
      scrollLock.value = true
      scrollPosition.value = e.clientX
    }

    // 取消鼠标跟随滚动条
    const eventUp = () => {
      scrollLock.value = false
    }

    const eventMove = (e) => {
      const currentTable = document.querySelector(`#${tableId.value}`)
      const el = ref(
        currentTable.getElementsByClassName('el-table__body-wrapper')[0]
      )
      if (scrollLock.value) {
        el.value.scrollLeft -=
          -scrollPosition.value + (scrollPosition.value = e.clientX)
      }
    }
    const amuntPercentage = (prop, row) => {
      const key = prop.replace('total', 'percentage')
      if (!Object.keys(row).length || !row[key]) {
        return 0
      }
      return Number(row[key].toFixed(2) || 0)
    }

    const store = useStore()
    const isGoodsRank = computed(() =>
      ['/shop/detail', '/goods/rank'].includes(store.state.nowUrl)
    )

    const route = useRoute()
    const downloadNameComputed = computed(() => {
      return `${route.meta.title}-${props.downloadName}${getTime()}`
    })

    const dataSortFn = (order, prev, next) => {
      const constant =
        order === 'ascending' ? 999999999999999 : -999999999999999
      return (
        (prev || prev === 0 ? prev : constant) -
        (next || next === 0 ? next : constant)
      )
    }
    // 修改列表排序逻辑 数据为空时排到最后
    const sortableMethod = ({ order, prop }) => {
      if (!order) {
        tableRow.value = JSON.parse(JSON.stringify(props.tableData.rows))
        return
      }
      tableRow.value.sort((prev, next) => {
        const sortOrder =
          order === 'ascending'
            ? dataSortFn(order, prev[prop], next[prop]) || -1
            : order === 'descending'
              ? dataSortFn(order, next[prop], prev[prop]) || 1
              : 0
        return sortOrder
      })
    }

    // 计算当前合并多少行
    const getColumnsLength = (column) => {
      if (column.children) {
        return column.children.reduce((current, obj) => {
          return current + getColumnsLength(obj)
        }, 0)
      } else {
        return 1
      }
    }

    // 最底层合计行合并列，只有下载显示
    const arraySpanMethod = ({
      row,
      column,
      rowIndex,
      columnIndex
    }) => {
      if (row.category_name.indexOf('销售总金额') > -1) {
        if (!columnIndex) {
          return [1, 1]
        } else if (columnIndex === 1) {
          return [1, summaryLength]
        } else {
          return [0, 0]
        }
      }
    }
    let summaryLength = 0
    const download = (idName, filename = 'data', isDownload = true) => {
      if (!isDownload) return
      summaryLength = columnsTable.columns.reduce((current, obj) => {
        return current + getColumnsLength(obj)
      }, 0)
      // 店铺需要加一列链接
      isShop.value && (summaryLength += 1)

      const copyTage = isPercentage.value
      isPercentage.value = false
      tableRow.value.push({ category_name: summaryMethod() })
      resetTableHead(false)

      downloadTableLog(props.index)

      nextTick(() => {
        tableToExcel(idName, filename)
        summaryLength = 0
        tableRow.value.pop()
        isPercentage.value = copyTage
      })
    }

    const isDetailRoute = computed(() => {
      return store.state.permission.includes('product.details')
    })
    const isShop = computed(() => {
      return columnsTable.columns.some((c) =>
        ['today_price', 'pic_url'].includes(c.prop)
      )
    })
    const tableId = computed(() => {
      return `downloadTable${props.index}${document.querySelectorAll('.chart-table').length}`
    })
    const goShopDetail = (row) => {
      emit('goShop', row)
    }
    return {
      isPercentage,
      download,
      tableId,
      isDetailRoute,
      goShopDetail,
      tableRow,
      isGoodsRank,
      columnsTable,
      firstArr,
      secondArr,
      formatClass,
      formatNum,
      isFixed,
      tableToExcel,
      downloadTable,
      picRoute,
      isDisabledBtn,
      noData,
      tableText,
      eventDown,
      eventUp,
      eventMove,
      scrollPosition,
      scrollLock,
      downloadNameComputed,
      sortableMethod,
      isShop,
      amuntPercentage,
      arraySpanMethod
    }
  }
})
</script>
<style lang="scss">
.table-search-tooltip {
  max-width: 200px;
}
.table-header-tooltip.el-popper {
  z-index: 3000 !important;
}
.chart-table {
  padding: 25px 20px 24px 27px;
  background: #fff;
  margin-top: 16px;
  margin-bottom: 12px;
  border-radius: 4px;
  position: relative;
  th.ascending,
  th.descending {
    background: #edeef0 !important;
  }
  .pro-link {
    // display: none;
    visibility: hidden;
    overflow: hidden;
    height: 0;
    div {
      height: 0;
      overflow: hidden;
    }
  }
  .preferential {
    color: #435b8e;
    cursor: pointer;
  }
  .el-button {
    &:hover {
      border-color: #f04848 !important;
      background: rgb(255, 246, 246);
      i {
        color: #f04848;
      }
    }
  }
  .table-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    .disabledBtn,
    .disabledBtn:hover {
      cursor: no-drop;
      border-color: #d0d3d9 !important;
      background: #f7f8fa !important;
      i {
        color: #84868c !important;
      }
    }
    .el-button--default {
      position: absolute;
      right: 0;
    }
    .el-checkbox-group {
      display: flex;
      height: 16px;
      align-items: center;
      &:nth-child(1) {
        padding-right: 18px;
        border-right: 1px solid #e9e9f2;
      }
      &:nth-child(2) {
        padding-left: 18px;
        padding-right: 18px;
        border-right: 1px solid #e9e9f2;
      }
    }
    .el-checkbox__label {
      display: flex !important;
      align-items: center;
      .icon-bangzhu {
        margin-left: 4px;
        color: rgb(197, 198, 205);
      }
    }
    .el-button--small {
      width: 36px;
      height: 36px;
      padding: 0;
      margin-left: 16px;
      border-radius: 2px;
    }
  }
  .goods-detail {
    display: flex;
    img {
      width: 40px;
      height: 40px;
    }
    .picRoute {
      color: #435b8d;
      cursor: pointer;
    }
    & > div {
      display: flex;
      flex-direction: column;
      padding-left: 12px;
      div:first-child {
        width: 244px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .goods-name {
        width: 248px;
        display: flex;
        justify-content: space-between;
        span {
          color: #242426;
          font-size: 12px;
          line-height: 18px;
          width: 49%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    .goods-price {
      color: #f04848 !important;
    }
  }
  .cell {
    display: flex;
    align-items: center;
    color: #545559;
    .iconfont {
      color: rgb(197, 198, 205);
      margin-left: 4px;
    }
    .amount-total {
      width: 100%;
    }
    .el-progress {
      width: 100%;
      margin-top: 6px;
      span {
        color: #84868C;
        font-size: 12px;
      }
      .el-progress-bar__inner {
        right: 0;
        left: inherit;
      }
      .el-progress__text {
        min-width: 47px;
      }
    }
  }
  .red {
    color: #f04848;
  }
  .green {
    color: #539558;
  }
}
</style>
