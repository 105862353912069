<template>
  <router-view class="router-view" />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Layout'
})
</script>

<style>

</style>
