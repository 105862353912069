import loading from '@/components/loading.js'
import { nextTick } from 'vue'

function leftScroll (tableId, column, columnsTable, cb) {
  const currentTable = document.querySelector(`#${tableId}`)
  // 初次加载条数
  let start = 4
  // 加载步长
  const step = 6
  columnsTable.columns = column.slice(0, start)

  const bodyWrapper = currentTable.querySelector('.el-table__body-wrapper')

  bodyWrapper.onscroll = () => resetTable()

  let timeout = null
  const resetTable = () => {
    const height = currentTable.querySelector('.el-table__fixed-body-wrapper').clientWidth
    const height1 = bodyWrapper.clientWidth

    start = columnsTable.columns.length
    if (height - height1 - bodyWrapper.scrollLeft > 30 || start === column.length) {
      loading.close()
      return
    }
    loading.create()
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      columnsTable.columns.push(...column.slice(start, start + step))
      cb()
      nextTick(() => {
        start = start + step
        setTimeout(() => {
          resetTable()
        }, 100)
      })
    }, 100)
  }

  // 页面加载先执行一次，防止不出现滚动条
  setTimeout(() => {
    resetTable()
  }, 100)
}
export default leftScroll
