import * as echarts from 'echarts'
import { formatNum } from '@/utils/index'

function myData (name) {
  return name.map(text => {
    if (text.length > 8) {
      return text.slice(0, 8) + '...'
    }
    return text
  })
}
export default function line (amount, num, name) {
  /* option 开始 */
  const option = {
    /* baseOption 开始 */
    baseOption: {
      timeline: {
        show: false,
        data: []
      },
      legend: { // 图例
        show: false,
        top: '5%', // 图例距离顶部距离
        left: 'center', // 图例距离左侧距离(此处水平居中)
        textStyle: { // 图例文本样式
          color: '#545559'
        }
      },
      tooltip: {
        trigger: 'axis',
        confine: true,
        padding: 12,
        className: 'echart-popup',
        axisPointer: {
          type: 'shadow'
        }
      },
      // 网格grid区域
      grid: [{
        show: false,
        left: '7%',
        top: '0',
        bottom: '0',
        containLabel: true, // grid 区域是否包含坐标轴的刻度标签。
        width: '36%' // grid 组件的宽度。默认自适应。
      }, {
        show: false,
        left: '51%',
        top: '0px',
        right: '0',
        bottom: '0',
        tooltip: {
          show: false // 中间部分的提示框不显示也没必要显示
        }
      }, {
        show: false,
        right: '7%',
        top: '0',
        bottom: '0',
        containLabel: true, // 区域是否包含坐标轴的刻度标签。
        width: '36%' // grid 组件的宽度。默认自适应。
      }],
      // X轴线配置
      xAxis: [
        { // 左侧区域
          show: true,
          gridIndex: 0, // x 轴所在的 grid 的索引，默认位于第一个 grid。[ default: 0 ]
          type: 'value', // 轴线类型: 数值轴
          position: 'top', // 轴线位置(此处位于顶部)
          inverse: true, // 是否是反向坐标轴.[ default: false ]
          axisLine: {
            show: false // 轴线不显示
          },
          axisTick: {
            show: false // 轴线刻度不显示
          },
          axisLabel: { // 轴线刻度标签
            show: false // 显示刻度标签
          },
          splitLine: { // 垂直于X轴的分隔线
            show: true, // 显示分隔线
            lineStyle: { // 分隔线样式
              color: '#E9ECF2',
              width: 1,
              type: 'dashed'
            }
          }
        },
        { // 中间区域
          gridIndex: 1,
          show: false, // 中间部分不显示X轴
          nameLocation: 'center'
        },
        { // 右侧区域
          show: true,
          gridIndex: 2,
          type: 'value',
          position: 'top',
          inverse: false, // 是否是反向坐标轴.[ default: false ]
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#E9ECF2',
              width: 1,
              type: 'dashed'
            }
          }
        }],
      // Y轴线配置
      yAxis: [
        { // 左侧区域
          gridIndex: 0, // y 轴所在的 grid 的索引，默认位于第一个 grid。[ default: 0 ]
          type: 'category', // 轴线类型: 类目轴
          boundaryGap: true, // 坐标轴两边留白策略，类目轴和非类目轴的设置和表现不一样。
          position: 'right', // y轴的位置。'left' or 'right'
          axisLine: {
            show: false // y轴线不显示
          },
          axisTick: {
            show: true, // y轴线刻度不显示
            lineStyle: { // 刻度线样式
              color: '#fff'
            }
          },
          axisLabel: {
            show: false // 刻度标签不显示
          },
          data: name // Y轴(这里是类目轴)的类目数据
        },
        {
          gridIndex: 1, // 中间区域
          type: 'category',
          boundaryGap: true,
          nameLocation: 'center',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            align: 'center',
            show: true, // 显示中间部分的Y轴刻度标签
            textStyle: { // 标签样式
              color: '#545559',
              fontSize: 12
            }
          },
          data: myData(name)
        },
        { // 右侧区域
          gridIndex: 2,
          type: 'category',
          boundaryGap: true,
          position: 'left',
          axisLine: {
            show: false
          },
          axisTick: {
            show: true,
            lineStyle: { // 刻度线样式
              color: ''
            }
          },
          axisLabel: {
            show: false
          },
          data: name
        }],
      series: []
    },
    /* baseOption 结束 */
    options: []
  }
  /* option 结束 */
  /* 逐个为配置项中的 data 和 series 赋值 */
  for (let i = 0; i < name.length; i++) {
    // 此处中间部分的系列不必设置
    option.options.push({
      series: [
        {
          name: '销售件数', // 系列名称
          type: 'bar',
          barWidth: 15, // 柱子宽度
          xAxisIndex: 0, // 对应在X轴的grid索引
          yAxisIndex: 0, // 对应在Y轴的grid索引
          label: {
            show: true,
            position: 'left',
            color: '#84868C',
            fontSize: 12,
            formatter: function (value) {
              return formatNum(value.data)
            }
          },
          itemStyle: { // 柱条样式。
            color: new echarts.graphic.LinearGradient(
              1, 0, 0, 1,
              [
                { offset: 0, color: '#8BABFF' },
                { offset: 1, color: '#9894FF' }
              ]
            ),
            borderRadius: [2, 0, 0, 2]
          },
          data: num // 系列中的数据内容数组
        },
        {
          name: '销售金额', // 系列名称
          type: 'bar',
          barWidth: 15, // 销售金额柱子宽度
          xAxisIndex: 2, // 对应在X轴的grid索引
          yAxisIndex: 2, // 对应在Y轴的grid索引
          label: {
            show: true,
            position: 'right',
            color: '#84868C',
            fontSize: 12,
            formatter: function (value) {
              return formatNum(value.data)
            }
          },
          itemStyle: { // 柱条样式。
            color: new echarts.graphic.LinearGradient(
              1, 0, 0, 1,
              [
                { offset: 0, color: '#FAD282' },
                { offset: 1, color: '#FFCAA2' }
              ]
            ),
            borderRadius: [0, 2, 2, 0]
          },
          data: amount // 系列中的数据内容数组
        }
      ]
    })
  }

  return option
}
