<template>
  <div class="chart-bottom-filter">
    <div
      v-for="(d, i) of data" :key="i"
      :class="`tag-${lineNumber} ${d.isClose ? 'close-tag' : ''}`"
      @click="closeTag(d)"
    >
      <div v-if="typeChart === 'bar' && !d.isClose" class="iconfont icon-xianshi"></div>
      <div v-else-if="typeChart === 'bar' && d.isClose" class="iconfont icon-yincang"></div>
      <div v-else class="tag" :style="{'background': d.color}"></div>
      <div class="tag-name">{{ d.name }}</div>
    </div>
    <div v-if="!!isshowAllData" @click="getData(isshowAllData === 1)" class="more-data">
      {{isshowAllData === 1 ? "展开" : "收起"}}
      <span class="iconfont" :class="isshowAllData === 1 ?'icon-celan_zhankai' : 'icon-celan_shouqi'"></span>
    </div>
  </div>
</template>
<script>
import { defineComponent, watch, ref, nextTick } from 'vue'
import { echartData, resetData } from './charts-condition-fn'
import { useStore } from 'vuex'

export default defineComponent({
  props: {
    option: Object,
    typeChart: String,
    lineNumber: {
      type: Number,
      default: 7
    }
  },
  setup (props) {
    const data = ref([])
    watch(props.option, () => getData())
    watch(() => props.typeChart, () => getData())
    const store = useStore()
    const { state, commit } = store
    /**
     * 0 隐藏展开收起按钮
     * 1 当前按钮文案为展开
     * 2 当前按钮文案为收起
    */
    const isshowAllData = ref(0)
    const getData = (isAll = false) => {
      resetData(props.option)
      const { sales_amount: SA, sales_amount_bar: SAB, sales_num: SN, sales_num_bar: SNB } = echartData
      let formatArr = []
      if (props.typeChart !== 'line') {
        formatArr = SAB?.length ? SAB : (SNB || [])
      } else {
        formatArr = SA?.data.length ? SA.data : (SN?.data?.length ? SN.data : [])
      }
      const originData = JSON.parse(JSON.stringify(formatArr)).map(l => {
        return { name: l.name || l.legend, color: l.color }
      })
      isshowAllData.value = originData.length >= 3 * props.lineNumber ? !isAll ? 1 : 2 : 0

      originData.forEach(d => (d.isClose = state.closeArr.includes(d.name)))
      data.value = !isAll ? originData.splice(0, 3 * props.lineNumber) : originData
      filterData()
    }

    const closeTag = (d) => {
      d.isClose = !d.isClose
      commit('setcloseArr', data.value.filter(d => d.isClose).map(d => d.name))
      filterData()
    }

    const filterData = () => {
      const type = props.typeChart
      resetData(props.option)
      // 延迟一下，页面加载会修改页面，不延迟会被页面加载时的数据覆盖
      nextTick(() => {
        if (type === 'line') {
          if (echartData?.sales_amount?.data) {
            echartData.sales_amount.data = echartData.sales_amount.data.filter(d => {
              return !state.closeArr.includes(d.legend)
            })
          }
          if (echartData?.sales_num?.data) {
            echartData.sales_num.data = echartData.sales_num.data.filter(d => {
              return !state.closeArr.includes(d.legend)
            })
          }
        } else {
          if (echartData?.sales_amount_bar) {
            echartData.sales_amount_bar = echartData.sales_amount_bar.filter(d => {
              return !state.closeArr.includes(d.name)
            })
          }
          if (echartData?.sales_num_bar) {
            echartData.sales_num_bar = echartData.sales_num_bar.filter(d => {
              return !state.closeArr.includes(d.name)
            })
          }
        }
      })
    }

    return { data, closeTag, isshowAllData, getData }
  }
})
</script>
<style lang="scss">
  .chart-bottom-filter {
    display: flex;
    flex-wrap: wrap;
    padding: 0 12px;
    div {
      color: #545459;
    }
    &>div {
      font-size: 12px;
      margin-bottom: 12px;
      cursor: pointer;
    }
    .icon-xianshi {
      margin-right: 8px;
      color: rgb(67, 67, 72);
    }
    .icon-yincang {
      margin-right: 8px;
      color: #84868c;
    }
    .tag-name {
      flex: 1;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 17px;
    }
    .close-tag {
      .tag {
        background: rgb(132, 133, 139) !important;
      }
      .tag-name {
        color: #84868C;
      }
    }
    .tag-7 {
      width: calc(100% / 7);
      display: flex;
      align-items: center;
    }
    .tag-5 {
      width: calc(100% / 5);
      display: flex;
      align-items: center;
    }
    .tag-2 {
      width: calc(100% / 2);
      display: flex;
      align-items: center;
    }
    .tag {
      width: 8px;
      height: 8px;
      border-radius: 2px;
      margin-right: 12px;
    }
    .more-data {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #84868C;
      margin-bottom: 12px;
      .iconfont {
        margin-left: 7px;
        color: #84868C;
      }
    }
  }
</style>
