<template>
  <div class="top-brand-radio-container">
    <el-radio-group v-model="radio" @change="onChange">
    <el-radio label="all">展示TOP+关注{{name}}</el-radio>
    <el-radio label="self">仅展示关注{{name}}</el-radio>
  </el-radio-group>
</div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'TopBrandRadio',
  props: {
    defaultTopBrandValue: {
      type: String,
      default: 'all'
    },
    brandIds: {
      type: String,
      default: ''
    },
    brandType: String
  },
  emits: {
    onTopBrandRadioEvent: null
  },
  setup (props, context) {
    const radio = ref(props.defaultTopBrandValue)
    const nameType = { shop: '店铺', brand: '品牌' }
    const pageName = nameType[props.brandType]
    const onChange = val => {
      if (val === 'self' && !props.brandIds) {
        ElMessage.error(`无关注${pageName}，将为您展示TOP${pageName}数据`)
        radio.value = 'all'
      }
      context.emit('onTopBrandRadioEvent', radio.value)
    }

    return {
      name: pageName,
      radio,
      onChange
    }
  }
})
</script>
